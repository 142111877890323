import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../../api/ApiUtils";
import { ToasterError } from "../../../helper/ToasterHelper";
import { useNavigate } from "react-router-dom";

function CompanyReport() {
  const [contacts, setContacts] = useState([]);
  const [sortColumn, setSortColumn] = useState("company_name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const fetchContactsList = async () => {
    await ApiUtils.getContactsList(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res?.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res ? res.data.data.data : []);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
        setContacts([]);
      });
  };
  useEffect(() => {
    fetchContactsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const tableData = contacts?.map((item) => {
    const staticData = {
      company_name: item.company_name,
      company_type:
        item.company_type === 1
          ? "Import"
          : item.company_type === 2
          ? "Export"
          : item.company_type === 3
          ? "Manufactured"
          : "",
      contact_name: item.contact_name,
      phone_number: item.phone_number,
      email: item.email,
      id: item.id,
      country_id: item.country_id,
      mobile_number: item.mobile_number,
    };
    return staticData;
  });

  const onPreviewCompany = (row) => {
    navigate(`/company-preview/${row.id}`);
  };

  const columns = [
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },
    {
      dataField: "company_type",
      text: "Company Type",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },

    {
      dataField: "contact_name",
      text: "Contact Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },

    {
      dataField: "phone_number",
      text: "Contact Phone",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },
    {
      dataField: "email",
      text: "Contact Email",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },
    // {
    //   dataField: "",
    //   text: "Shipment Under 50Kg",
    //   sort: true,
    //   onSort: async (field, order) => {
    //     setSortColumn(field);
    //     setSortDirection(order);
    //     sortContactsFunc(field, order);
    //   },
    // },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      formatter: (_cellContent, row) => (
        <>
          <Button
            className="me-3 text-info tooltip-on-action btn1"
            onClick={() => onPreviewCompany(row)}
          >
            <i className="ri ri-eye-line font-size-18"></i>
            <span className="tooltiptext">View</span>
          </Button>
        </>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getContactsList(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const sortContactsFunc = async (field, order) => {
    await ApiUtils.getContactsList(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };

  const handleDownload = () => {
    ApiUtils.getCompanyReport()
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.data, "_blank");
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={8}>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Label
                                      htmlFor="shipment-date-input"
                                      className="form-label"
                                    >
                                      Search
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={(e) => onSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                md={4}
                                className="d-flex justify-content-end"
                              >
                                <Button
                                  type="button"
                                  color="info"
                                  className="waves-effect waves-light"
                                  style={{ height: "fit-content" }}
                                  onClick={handleDownload}
                                >
                                  <i className=" ri-download-2-fill font-size-15 me-1" />
                                  Download
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyReport;
