import React from "react";
import {
  Row,
  Col,
} from "reactstrap";
import ShipmentTable from "../CommonComponents/ShipmentTable";
import {useNavigate} from "react-router-dom";
function ShipmentArchiveList() {

  const navigate = useNavigate();
  const navigateToNewShipment = () => {
    navigate("/add-new-shipment");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 my-4">Shipments List</h4>
                <div className="page-title-right">
                  <div className="mb-0 my-4 text-center">
                    <button
                        type="button"
                        onClick={navigateToNewShipment}
                        className="waves-effect waves-light btn btn-info"
                    >
                      New Shipment
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
         <ShipmentTable/>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShipmentArchiveList;
