// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tableRow {
    width: 50%;
}
.action-dropdown-invoice {
    display: flex;
    gap: 10px;
        color: #505d69;
}
.filter-button {
    border-radius: 50px;
    width: 100px;
}


.vertical-line {
    height: 100%;
    width: 1px;
    background-color: #000000;
    margin: 0 auto;
}

.left-content,
.right-content {
    flex: 1 1;
}

.strait-line {
    border-right: 1px dashed lightgray;
    font-size: "13px";
}`, "",{"version":3,"sources":["webpack://./src/assets/custom/custom2.css"],"names":[],"mappings":";AACA;IACI,UAAU;AACd;AACA;IACI,aAAa;IACb,SAAS;QACL,cAAc;AACtB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,cAAc;AAClB;;AAEA;;IAEI,SAAO;AACX;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;AACrB","sourcesContent":["\n.tableRow {\n    width: 50%;\n}\n.action-dropdown-invoice {\n    display: flex;\n    gap: 10px;\n        color: #505d69;\n}\n.filter-button {\n    border-radius: 50px;\n    width: 100px;\n}\n\n\n.vertical-line {\n    height: 100%;\n    width: 1px;\n    background-color: #000000;\n    margin: 0 auto;\n}\n\n.left-content,\n.right-content {\n    flex: 1;\n}\n\n.strait-line {\n    border-right: 1px dashed lightgray;\n    font-size: \"13px\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
