import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "reactstrap";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoSmDark from "../../assets/images/logo-sm-dark.png";

//Import mega menu image
import megamenuImg from "../../assets/images/megamenu-img.png";

// Redux Store
// import { toggleRightSidebar } from "../../store/actions";

function Header(props) {
  const [isSearch, setIsSearch] = useState(false);

  const toggleSearch = () => {
    setIsSearch(!isSearch);
  };

  const toggleMenu = () => {
    props.openLeftMenuCallBack();
  };

  const toggleRightbar = () => {
    props.toggleRightSidebar();
  };

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const [textSearchs, setTextSearchs] = useState();
  const navigate = useNavigate();

  const textSearch = (e) => {
    setTextSearchs(e.target.value);
  };

  const onSearch = (e) => {
    e.preventDefault();
    navigate(`/shipments-archive-list`);
    localStorage.setItem("textSearch", textSearchs);
    setTextSearchs("");
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={"bg-info"}>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSmDark} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                  <h3>MEL</h3>
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={logoSmLight} alt="" height="22" /> */}
                  <img alt={"MEL"} src="/MEL-logo.png" style={{width: "175px"}}/>
                </span>
                <span className="logo-lg">
                  {/* <img src={logoLight} alt="" height="20" /> */}
                  <img alt={"MEL"} src="/MEL-logo.png" style={{width: "175px"}}/>
                </span>
              </Link>
            </div>
            <Form className="app-search d-none d-lg-block" onSubmit={onSearch}>
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control text-dark bg-white"
                  placeholder="Search"
                  onChange={textSearch}
                  value={textSearchs}
                />
                <span className="ri-search-line text-dark"></span>
              </div>
            </Form>
            <Button
              color="none"
              type="button"
              size="sm"
              onClick={toggleMenu}
              className="px-3 font-size-24 d-lg-none header-item text-dark"
              data-toggle="collapse"
              data-target="#topnav-menu-content"
            >
              <i className="ri-menu-2-line align-middle text-dark"></i>
            </Button>
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Button
                type="button"
                color="none"
                onClick={toggleFullscreen}
                className="header-item noti-icon waves-effect text-dark"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line text-dark"></i>
              </Button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
