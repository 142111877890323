import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  CardTitle,
  CardText,
  Table,
  CardBody,
  Popover,
  PopoverBody,
} from "reactstrap";
import ApiUtils from "../../api/ApiUtils";
import { useNavigate, useParams } from "react-router-dom";
import { convertAmountToWords } from "../../helper/NumberToWords";
import * as html2pdf from "html2pdf.js";
import { ToasterError, ToasterSuccess } from "../../helper/ToasterHelper";
import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import logo from "../../assets/images/MEL-LOGO.png";

function InvoicePreview() {
  const [invoiceDetails, setInvoiceDetails] = useState("");
  console.log(
    "🚀 ~ file: InvoicePreview.js:27 ~ InvoicePreview ~ invoiceDetails:",
    invoiceDetails
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };
  const userData = useSelector((state) => state.authSlice);
  const isAuthorized = userData?.userDetails;

  const searchParam = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchInvoicePreviewDetails();
    if (!searchParam?.id) {
      navigate("/invoice-archive");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam?.id]);

  async function fetchInvoicePreviewDetails() {
    await ApiUtils.getInvoicePreview(`${searchParam?.id}`)
      .then((res) => {
        if (res.status === 200) {
          setInvoiceDetails(res.data.data);
        }
      })
      .catch((err) => navigate("/invoice-archive"));
  }

  const invoiceTotalAmountInWords = convertAmountToWords(
    Number(invoiceDetails?.total_amount)
  );
  const copyUrlButton = () => {
    ToasterSuccess("COPIED");
    setIsPopoverOpen(!isPopoverOpen);
  };
  const copyUrlButton2 = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const descriptionChemical = Number(
    invoiceDetails?.shipment?.chemical_container
  );
  const descriptionChemicalAmountInWords =
    convertAmountToWords(descriptionChemical);

  const descriptionPhysical = Number(
    invoiceDetails?.shipment?.physical_container
  );
  const descriptionPhysicalAmountInWords =
    convertAmountToWords(descriptionPhysical);

  const handleSend = () => {
    const InvoiceData = {
      id: invoiceDetails?.id,
    };
    ApiUtils.sendInvoiceToClient(InvoiceData)
      .then((res) => {
        if (res.status === 200) {
          ToasterSuccess(res.data.message);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const handlePayNow = () => {
    ApiUtils.generatePaymentUrl(`id=${invoiceDetails?.id}`)
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data;
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const markAsPaid = () => {
    ApiUtils.updateInvoiceStatus({ id: invoiceDetails?.id, status: 2 })
      .then((res) => {
        if (res.status === 200) {
          ToasterSuccess(res.data.message);
          fetchInvoicePreviewDetails();
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const handleDownload = () => {
    const element = document.getElementById("invoice-preview");
    const invoiceNumber = invoiceDetails?.id || "";
    const clone = element.cloneNode(true);

    // Adjust the width of the cloned element (e.g., increase it by 20%)
    clone.style.width = "100%";

    const buttons = clone.querySelectorAll(".my-4 button");
    buttons.forEach((button) => {
      button.parentNode.removeChild(button);
    });

    const pdfOptions = {
      format: "a4",
      orientation: "portrait",
    };

    html2pdf().from(clone).set(pdfOptions).save(`Invoice${invoiceNumber}.pdf`);
  };

  return (
    <React.Fragment>
      <div className="page-content" id="invoice-preview">
        <div className="container-fluid justify-content-center align-items-center">
          {/* Center the card horizontally and vertically */}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="d-flex gap-2">
                        <h5>Invoice No:</h5>
                        <p> {invoiceDetails?.id}</p>
                      </div>
                      <div className="d-flex gap-2">
                        <h5>Date:</h5>
                        <p>
                          {" "}
                          {invoiceDetails?.issue_date
                            ? new Date(invoiceDetails?.issue_date)
                                .toLocaleDateString("en-GB")
                                .replace(/\//g, "-")
                            : ""}{" "}
                        </p>
                      </div>
                      <div className="d-flex gap-2">
                        <h5>CDN:</h5>
                        <p>
                          {invoiceDetails?.shipment?.customs_decleration_number}
                        </p>
                      </div>
                    </div>
                    <img src={logo} alt="Invoice" style={{ width: "175px" }} />
                  </div>

                  <hr className="mt-3" />

                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Invoice To:</h5>
                      {invoiceDetails?.shipment?.client_contact.map(
                        (text, i) => (
                          <span key={i}>
                            {text?.company_name} <br />
                            {text?.contact_name} <br />
                            {text?.email}
                          </span>
                        )
                      )}
                    </div>
                    <div>
                      <h5>Pay To:</h5>
                      Middle East Labs
                      <br />
                      Building 195, 1St Floor,
                      <br />
                      West of Abu Fatira Al Herafia, Kuwait
                      <br />
                      Email : info@mideastlabs.com
                      <br />
                      Fax: +965 22251578
                    </div>
                  </div>
                  <div className="mt-3">
                    <h5>Broker:</h5>
                    {invoiceDetails?.shipment?.broker?.map((text, i) => {
                      return (
                        <React.Fragment key={i}>
                          <span>{text?.name}</span>
                        </React.Fragment>
                      );
                    })}
                    <br />
                    {invoiceDetails?.shipment?.broker?.map((text, i) => {
                      return (
                        <React.Fragment key={i}>
                          <span>{text?.mobile_number}</span>
                        </React.Fragment>
                      );
                    })}
                  </div>

                  <table class="table table-hover border mt-4">
                    <thead>
                      <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetails?.shipment?.chemical_container > 0 ? (
                        <tr>
                          <td style={{ textTransform: "capitalize" }}>
                            {descriptionChemicalAmountInWords} Chemical
                            Container{" "}
                          </td>
                          <td>
                            {invoiceDetails?.shipment?.chemical_container || 0}
                          </td>
                          <td>{Number(invoiceDetails?.chemical) || 0} KWD</td>
                          <td>
                            {invoiceDetails?.chemical_container_price || 0} KWD{" "}
                          </td>
                        </tr>
                      ) : null}
                      {invoiceDetails?.shipment?.physical_container > 0 ? (
                        <tr>
                          <td style={{ textTransform: "capitalize" }}>
                            {descriptionPhysicalAmountInWords} Physical
                            Container{" "}
                          </td>
                          <td>
                            {invoiceDetails?.shipment?.physical_container || 0}
                          </td>
                          <td>{Number(invoiceDetails?.physical) || 0} KWD</td>
                          <td>
                            {invoiceDetails?.physical_container_price || 0} KWD{" "}
                          </td>
                        </tr>
                      ) : null}

                      <>
                        {invoiceDetails.discount !== 0 && (
                          <tr>
                            <td
                              colSpan={2}
                              style={{ textTransform: "capitalize" }}
                            >
                              <b>Sub Total :</b> <br />
                            </td>
                            <td colSpan={2}>
                              <b>
                                {Number(
                                  invoiceDetails.totalBeforeDiscount
                                ).toFixed(2)}{" "}
                                Kwd
                              </b>
                            </td>
                          </tr>
                        )}
                        {invoiceDetails.discount !== 0 && (
                          <tr>
                            <td
                              colSpan={2}
                              style={{ textTransform: "capitalize" }}
                            >
                              <b>Total Discount:</b> <br />
                            </td>
                            <td colSpan={2}>
                              {invoiceDetails?.discount}{" "}
                              {invoiceDetails?.discount_type === "Fixed"
                                ? "KWD"
                                : "%"}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td
                            colSpan={2}
                            style={{ textTransform: "capitalize" }}
                          >
                            <b>
                              {invoiceDetails.discount === 0
                                ? "Total Amount in Words"
                                : "Total Amount After Discount in Words"}{" "}
                              :
                            </b>{" "}
                            <br />
                            {invoiceTotalAmountInWords} Kuwaiti Dinar
                          </td>
                          <td colSpan={2}>
                            <b>
                              Total Amount:{" "}
                              <span>
                                {" "}
                                {Number(invoiceDetails?.total_amount).toFixed(
                                  2
                                )}{" "}
                                KWD
                              </span>
                            </b>
                          </td>
                        </tr>
                        {invoiceDetails?.status === 2 && (
                          <tr>
                            <td
                              colSpan={2}
                              style={{ textTransform: "capitalize" }}
                            >
                              <b>Payment Method:</b> <br />
                            </td>
                            <td colSpan={2}>
                              <b>{invoiceDetails?.payment_method}</b>
                            </td>
                          </tr>
                        )}
                      </>
                    </tbody>
                  </table>
                  {invoiceDetails?.status === 2 ? (
                    <div className="my-4">
                      <Button
                        type="button"
                        color="info"
                        className="waves-effect waves-light mx-3"
                        onClick={handleDownload}
                      >
                        DOWNLOAD
                      </Button>
                    </div>
                  ) : isAuthorized !== null && invoiceDetails.status === 1 ? (
                    <div className="my-4" style={{ textAlign: "center" }}>
                      <Button
                        type="button"
                        color="success"
                        className="waves-effect waves-light"
                        onClick={markAsPaid}
                      >
                        MARK AS PAID
                      </Button>

                      <Button
                        type="button"
                        color="info"
                        className="waves-effect waves-light mx-3"
                        onClick={handleSend}
                      >
                        SEND TO CLIENT
                      </Button>

                      <Button
                        id="send-button"
                        type="button"
                        color="dark"
                        className="waves-effect waves-light"
                      >
                        SHARE INVOICE
                      </Button>

                      <Popover
                        placement="top"
                        isOpen={isPopoverOpen}
                        target="send-button"
                        toggle={togglePopover}
                        className="popover-card"
                      >
                        {/* <PopoverHeader>Send</PopoverHeader> */}
                        <PopoverBody style={{ width: "100px" }}>
                          <div className="d-flex align-center justify-content-between">
                            <CopyToClipboard
                              text={window.location.href}
                              onCopy={() => copyUrlButton()}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  marginTop: 5,
                                }}
                              >
                                <i
                                  className="ri-file-copy-2-line"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </div>
                            </CopyToClipboard>

                            <WhatsappShareButton
                              onClick={copyUrlButton2}
                              url={window.location.href}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </WhatsappShareButton>
                          </div>
                        </PopoverBody>
                      </Popover>
                    </div>
                  ) : (
                    isAuthorized === null &&
                    invoiceDetails.status === 1 && (
                      <div className="my-4" style={{ textAlign: "center" }}>
                        <Button
                          type="button"
                          color="info"
                          className="btn-lg waves-effect waves-light mx-3"
                          onClick={handlePayNow}
                        >
                          PAY NOW
                        </Button>
                      </div>
                    )
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InvoicePreview;
