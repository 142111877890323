const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  id: "",
  shipmentType: "",
  noOfContainer: "",
  stepNumber: "",
  shipmentDetails: "",
};
const shipmentSlice = createSlice({
  name: "shipmentData",
  initialState,
  reducers: {
    setShipmentId: (state, action) => {
      state.id = action.payload;
    },
    setTypeOfShipment: (state, action) => {
      state.shipmentType = action.payload;
    },
    setContainerNumber: (state, action) => {
      state.noOfContainer = action.payload;
    },
    setCurrentStepNumber: (state, action) => {
      state.stepNumber = action.payload;
    },
    setShipmentDetails: (state, action) => {
      state.shipmentDetails = action.payload;
    },
  },
});

export const {
  setShipmentId,
  setContainerNumber,
  setTypeOfShipment,
  setCurrentStepNumber,
  setShipmentDetails,
} = shipmentSlice.actions;
export default shipmentSlice.reducer;
