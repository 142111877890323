import React, { useEffect, useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { useParams } from "react-router-dom";
import {Card, CardBody, Col, Row} from "reactstrap";

function ShipmentLogs() {
    const [shipmentLogs, setShipmentLogs] = useState([]);
    const [shipmentDetails, setShipmentDetails] = useState({});

    const params = useParams();

    useEffect(() => {
        async function getShipmentLogs() {
            try {
                const response = await ApiUtils.getShipmentLogs(`ship_id=${params?.id}`);
                setShipmentLogs(response.data);
            } catch (error) {
                console.error("Error fetching shipment logs:", error);
            }
        }
        getShipmentLogs();

        async function getShipmentDetails() {
            try {
                const response = await ApiUtils.getShipmentById(`id=${params?.id}`);
                setShipmentDetails(response.data.data);
            } catch (error) {
                console.error("Error fetching shipment details:", error);
            }
        }
        getShipmentDetails();

    }, [params?.id]);

    console.info(shipmentLogs);

    const columns = [
        {
            dataField: "id",
            text: "ID",
        }
    ];

    // Render your shipmentLogs data here using JSX
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col xs={12}>
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 my-4">Shipment
                                    <span className={"text-info"}>{shipmentDetails.customs_decleration_number}</span> History
                                    <a target={"_blank"} href={`${process.env.REACT_APP_BASE_URL}get-kepa-shipment/?CDN=${shipmentDetails.customs_decleration_number}`}>
                                        <i className={"mdi mdi-open-in-new"} style={{fontSize: "20px", marginLeft: "10px"}}></i>
                                    </a>
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <p className="card-title-desc"></p>
                                    <table className={"table table-striped table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Action</th>
                                            <th>Remarks</th>
                                            <th>Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {shipmentLogs.length === 0 ? (
                                            <tr>
                                                <td colSpan="4" style={{textAlign:'center'}}>No shipment logs available</td>
                                            </tr>
                                        ) : (
                                            shipmentLogs.map((log, index) => (
                                                <tr key={index}>
                                                    <td>{log.id}</td>
                                                    <td style={{ textTransform: "capitalize", width: "180px" }}>
                                                        {log.api_name === "webhook" ? "KEPA Response" : log.api_name.replace("-", " ")}
                                                    </td>
                                                    <td>{log.remarks}</td>
                                                    <td style={{ width: "180px" }}>{log.created_at}</td>
                                                </tr>
                                            ))
                                        )}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );

}

export default ShipmentLogs;
