import { createContext, useContext, useState, useEffect } from "react";
import ApiUtils from "../api/ApiUtils";
import { ToasterError } from "./ToasterHelper";

const UserContext = createContext();

export function UserProvider({ children }) {
  const token = JSON.parse(localStorage.getItem("mel-user"));

  const [userRole, setUserRole] = useState({});

  useEffect(() => {
    async function getUserInfo() {
      try {
        if (token?.userToken) {
          const res = await ApiUtils.getProfile();
          const fetchedUserRole = res.data.data;
          setUserRole(fetchedUserRole);
        }
      } catch (error) {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      }
    }
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearUserData = () => {
    setUserRole({});
  };

  const setUserData = (data) => {
    setUserRole(data);
  };

  return (
    <UserContext.Provider value={{ userRole, clearUserData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserRole() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserProvider");
  }
  return context;
}
