import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import swal from "sweetalert";
import "../../assets/custom/custom2.css";

import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../api/ApiUtils";
import {
  ToasterError,
  ToasterSuccess,
  ToasterWarning,
} from "../../helper/ToasterHelper";
import { Link } from "react-router-dom";
import { formToJSON } from "axios";
const DataSampleList = () => {
  const [modalCenter, setModalCenter] = useState(false);
  const [items, setItems] = useState([{ flag: false, id: 1 }]);
  const [viewselectdatasample, setviewselectdatasample] = useState(true);
  const [viewfileds, setviewfileds] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedDataSample, setselectedDataSample] = useState("");
  const [searchedCasNumber, setsearchedCasNumber] = useState("");
  const [searchedInputValue, setSearchedInputValue] = useState("");
  const [dropdownFlag, setDropdownFlag] = useState(true);

  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSampleListData, setDataSampleListData] = useState([]);
  const [isEdit, setIsEdit] = useState("");
  const [dataSampleEditId, setDataSampleEditId] = useState();
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [containerTypeList, setContainerTypeList] = useState([]);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    select: "",
    hsa_code: "",
    component: "",
    SMDC: "",
    data_companent: "",
    CAS_Number: "",
    usage: "",
    authority: "",
  });

  const fetchDatsmples = async () => {
    await ApiUtils.getDataSample(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setDataSampleListData(res.data.data.data);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
        setDataSampleListData([]);
      });
  };
  useEffect(() => {
    fetchDatsmples();
    fetchTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  // const onPreviewDataSample = (row) => {
  //   const data = dataSampleListData?.find((d) => row.id === d.id);
  //   if (data) {
  //     window.open(data.smds, "_blank");
  //   }
  // };

  const tableData = dataSampleListData?.map((item) => {
    const staticData = {
      name: item.name,
      type:
        item.type === 1
          ? "Chemical"
          : item.type === 2
          ? "Physical"
          : item.type === 3
          ? "Chemical+Physical"
          : item.type === 4
          ? "Not under KEPA"
          : item.type === 5
          ? "Less then 50Kg"
          : "",
      hsa_code: item.hsa_code,
      components: item.data_companent,
      id: item.id,
      smds_file: item.smds,
    };
    return staticData;
  });
  async function fetchTypeList() {
    await ApiUtils.getContainerTypeList()
      .then((res) => {
        setContainerTypeList(res.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  const togCenter = () => {
    setIsEdit("Add");
    setModalCenter(!modalCenter);
    removeBodyCss();

    setContactDetails({
      name: "",
      select: "",
      hsa_code: "",
      component: "",
      SMDC: "",
    });
    setItems([{ flag: false, id: 1 }]);
    setviewselectdatasample(true);
    setviewfileds(false);
  };

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const authority = [
    { value: 1, label: "The Environment Public Authority" },
    { value: 2, label: "The Public Authority for Industry" },
    { value: 3, label: "Others" },
  ];
  const columns = [
    {
      dataField: "name",
      text: "Sample Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortDataSamplesFunc(field, order);
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortDataSamplesFunc(field, order);
      },
    },
    {
      dataField: "hsa_code",
      text: "HSA Code",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortDataSamplesFunc(field, order);
      },
    },
    {
      dataField: "smds_file",
      text: "MSDS",
      formatter: (_cellContent, row) => {
        return (
          <div>
            <Link to={row.smds_file} download target="_blank">
              <i className="ri-download-2-fill font-size-20 download-btn-inner" />
            </Link>
          </div>
        );
      },
    },

    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      formatter: (_cellContent, row) => (
        <>
          <UncontrolledDropdown className="text-center">
            <DropdownToggle tag="i" className="arrow-none">
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => onPreviewDataSample(row)}
              >
                <i className="ri ri-eye-line font-size-18"></i>
                View
              </DropdownItem> */}
              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => editDataSample(row)}
              >
                <i className="mdi mdi-pencil font-size-18"></i>
                Update
              </DropdownItem>

              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => deleteDataSample(row)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  const handleChange = (e, { name }) => {
    if (e?.label) {
      const selectedValue = e.value;
      setContactDetails({
        ...contactDetails,
        [name]: selectedValue,
      });
    } else {
      setContactDetails({
        ...contactDetails,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };
  const chooseDataComp = (object, id) => {
    const updatedItems = items.map((el) => {
      if (el.id === id) {
        return {
          [`name${el.id}`]: object.name,
          type: "front",
          [`cas_code${el.id}`]: object.cas_code,
          [`percentage${el.id}`]: object?.percentage,
          [`id`]: el.id,
          flag: false,
        }; // Create a new object with 'flag' set to true
      }
      return el; // Return unchanged items
    });
    // console.log(updatedItems);
    setItems(updatedItems); // Update the state with the modified array
  };
  const search = (e, id) => {
    if (e.target.value === "") {
      setsearchedCasNumber([]);
      // setDropdownFlag(false);
      const updatedItems = items.map((el) => {
        if (el.id === id) {
          return { ...el, flag: false }; // Create a new object with 'flag' set to true
        }
        return el; // Return unchanged items
      });

      setItems(updatedItems); // Update the state with the modified array
    } else {
      onSearch1(e.target.value);
      const updatedItems = items.map((el) => {
        if (el.id === id) {
          return { ...el, flag: true, [`cas_code${el.id}`]: e.target.value }; // Create a new object with 'flag' set to true
        }
        return { ...el, flag: false }; // Return unchanged items
      });

      setItems(updatedItems); // Update the state with the modified array
      setDropdownFlag(true);
    }
  };

  const handleDataChange = (e, { name }) => {
    // console.log(e);
    if (e?.label) {
      const selectedValue = e.value;
      setselectedDataSample({
        [name]: selectedValue,
      });
    }
    fillDataONSelectedSample(e);
  };
  const fillDataONSelectedSample = (row) => {
    const data = dataSampleListData?.find((d) => row.id === d.id);
    let updatedata = {
      name: data.name,
      select: data.type,
      unit: data.unit,
      hsa_code: data.hsa_code,
      usage: data.usage,
      authority: data.authority,
      SMDC: data?.smds,
    };
    // console.log(updatedata);
    setItems(
      data.data_companent && data.data_companent.length > 0
        ? data.data_companent.map((i) => ({
            [`name${i.id}`]: i.name,
            [`cas_code${i.id}`]: i.cas_code,
            [`percentage${i.id}`]: i?.percentage,
            [`id`]: i.id,
          }))
        : [1]
    );

    setContactDetails({
      ...updatedata,
      component_name: data.data_companent.map((d) => ({
        [`name${d.id}`]: d.name,
      })),
      cas_code: data.data_companent.map((d) => ({
        [`cas_code${d.id}`]: d.cas_code,
      })),
      percentage: data.data_companent.map((d) => ({
        [`percentage${d.id}`]: d.percentage,
      })),
    });

    // setDataSampleEditId(row.id);
  };
  const editDataSample = (row) => {
    setviewfileds(true);
    setviewselectdatasample(false);
    setIsEdit("Edit");
    const data = dataSampleListData?.find((d) => row.id === d.id);

    let updatedata = {
      name: data.name,
      select: data.type,
      unit: data.unit,
      hsa_code: data.hsa_code,
      usage: data.usage,
      authority: data.authority,
      SMDC: data?.smds,
    };

    setItems(
      data.data_companent && data.data_companent.length > 0
        ? data.data_companent.map((i) => ({
            [`name${i.id}`]: i.name,
            [`cas_code${i.id}`]: i.cas_code,
            [`percentage${i.id}`]: i?.percentage,
            [`id`]: i.id,
          }))
        : [1]
    );

    setContactDetails({
      ...updatedata,
      component_name: data.data_companent.map((d) => ({
        [`name${d.id}`]: d.name,
      })),
      cas_code: data.data_companent.map((d) => ({
        [`cas_code${d.id}`]: d.cas_code,
      })),
      percentage: data.data_companent.map((d) => ({
        [`percentage${d.id}`]: d.percentage,
      })),
    });
    setModalCenter(true);
    setDataSampleEditId(row.id);
  };

  const deleteDataSample = (row) => {
    const data = dataSampleListData?.find((d) => row.id === d.id);
    swal({
      title: "Are you sure?",
      text: "Do you want to delete data sample?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApiUtils.deleteDataSample(data.id)
          .then((res) => {
            if (res.status === 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setCurrentPage(1);
              fetchDatsmples();
            }
          })
          .catch((error) => {
            if (error && error.data && error.data.message) {
              ToasterError(error.data.message);
            } else if (error && error.data) {
              ToasterError(error.data);
            } else if (error) {
              ToasterError(error);
            }
          });
      } else {
        swal({
          title: "Data Sample not deleted!",
          icon: "error",
        });
      }
    });
  };

  const handleUserInput = (e, itemId) => {
    const { name, value } = e.target;
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const modalClose = () => {
    setModalCenter(false);
    setIsEdit(false);
    setviewfileds(false);
    setviewselectdatasample(true);
    setDataSampleEditId("");
    setItems([1]);
    setContactDetails({
      name: "",
      select: "",
      hsa_code: "",
      component: "",
      SMDC: "",
    });
  };

  const onSubmitUser = (event, error, value) => {
    event.preventDefault();
    if (error.length >= 1) {
      ToasterWarning("Please input all fields");
      return;
    }

    const formData = new FormData();
    const data = {};

    for (let [name, value] of formData.entries()) {
      if (name !== "cas_code" && name !== "name" && name !== "percentage") {
        data[name] = value;
      }
    }

    data.data_component = items.map((component, index) => {
      if (component.type === "front") {
        formData.append(
          `data_component[${index}][name]`,
          component[`name${component.id}`] !== undefined
            ? component[`name${component.id}`]
            : ""
        );
        formData.append(
          `data_component[${index}][cas_code]`,
          component[`cas_code${component.id}`] !== undefined
            ? component[`cas_code${component.id}`]
            : ""
        );
        formData.append(
          `data_component[${index}][percentage]`,
          component[`percentage${component.id}`] !== undefined
            ? component[`percentage${component.id}`]
            : ""
        );
      } else {
        formData.append(`data_component[${index}][id]`, component.id);
        formData.append(
          `data_component[${index}][name]`,
          component[`name${component.id}`] !== undefined
            ? component[`name${component.id}`]
            : ""
        );
        formData.append(
          `data_component[${index}][cas_code]`,
          component[`cas_code${component.id}`] !== undefined
            ? component[`cas_code${component.id}`]
            : ""
        );
        formData.append(
          `data_component[${index}][percentage]`,
          component[`percentage${component.id}`] !== undefined
            ? component[`percentage${component.id}`]
            : ""
        );
      }
      return component;
    });
    //console.log(formToJSON(formData));
    const {data_component} = formToJSON(formData)
     const isRedundant=hasRedundantObjects(data_component)
    if(isRedundant){
      ToasterError('data samples cant be repeated')
      return;
    }

    formData.append("name", event.target.name.value);
    formData.append("hsa_code", event.target.hsa_code.value);
    formData.append("unit", event.target.unit.value);
    const fileInput = event.target.elements.SMDC;
    if (fileInput.files.length > 0) {
      formData.append("smds", fileInput.files[0]);
    }

    formData.append("type", event.target.select.value);
    formData.append("authority", event.target.authority.value);
    formData.append("usage", event.target.usage.value);

    if (isEdit === "Add") {
      ApiUtils.addDataSample(formData)
        .then((res) => {
          if (res.status === 200) {
            fetchDatsmples();
            setModalCenter(false);
            setItems([1]);
            setTimeout(() => {
              setContactDetails({
                name: "",
                select: "",
                hsa_code: "",
                unit: "",
                component: "",
                SMDC: "",
              });
            }, 100);
            ToasterSuccess(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
    if (isEdit === "Edit") {
      formData.append("id", dataSampleEditId);

      const fileInput = event.target.elements.SMDC;
      if (fileInput.files.length > 0) {
        formData.append("smds", fileInput.files[0]);
      }
      console.log(formToJSON(formData));
      ApiUtils.editDataSample(formData)
        .then((res) => {
          if (res.status === 200) {
            fetchDatsmples();
            setModalCenter(false);
            setItems([1]);
            setDataSampleEditId("");
            setContactDetails({
              name: "",
              select: "",
              hsa_code: "",
              unit: "",
              component: "",
              SMDC: "",
            });
            ToasterSuccess(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };
const hasRedundantObjects=(array)=>{
  const uniqueObjects=new Set();
  for(const obj of array){
    const stringObject = JSON.stringify((obj.cas_code))
    if(uniqueObjects.has(stringObject)){
      return true;
    }
    uniqueObjects.add(stringObject)
  }
  return false
}
  // const defaultSorted = [
  //   {
  //     dataField: "id",
  //     order: "asc",
  //   },
  // ];

  const addItem = () => {
    const newItem = {
      id: new Date().getTime(),
      type: "front",
      flag: false,
    };
    setItems([...items, newItem]);
  };

  const removeItem = (id, type) => {
    if (type === "front") {
      setItems(items?.filter((item) => item.id !== id));
    } else {
      ApiUtils.deleteDataSampleListComponent(id);
      setItems(items?.filter((item) => item.id !== id));
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getDataSample(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setDataSampleListData(res.data.data.data);
        }
      })
      .catch((err) => setDataSampleListData([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const searchHandleChange1 = async (value) => {
    await ApiUtils.getDataSampleComponents(value)
      .then((res) => {
        if (res.status === 200) {
          setsearchedCasNumber(res.data.data);
        }
      })
      .catch((err) => setDataSampleListData([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch1 = useCallback(debounce(searchHandleChange1), []);

  const sortDataSamplesFunc = async (field, order) => {
    await ApiUtils.getDataSample(
      `search=${searchValue}&sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setDataSampleListData(res.data.data.data);
        }
      })
      .catch((err) => setDataSampleListData([]));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Data Samples List</h4>

                <div className="page-title-right">
                  <div className="my-4 text-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light"
                      onClick={togCenter}
                    >
                      Add Data Sample
                    </Button>
                  </div>

                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop={true}
                  >
                    <ModalHeader toggle={modalClose}>
                      {isEdit === "Edit"
                        ? "Edit Data Sample"
                        : "Add Data Sample"}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm
                        onSubmit={(e, error, value) =>
                          onSubmitUser(e, error, value)
                        }
                      >
                        <Row>
                          {isEdit !== "Edit" && viewselectdatasample && (
                            <Row>
                              <Col md={12}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Select Data Sample To Auto Fill Data
                                  </Label>
                                  <Select
                                    defaultValue={{
                                      label: "",
                                      value: "",
                                    }}
                                    name="select2"
                                    options={tableData?.map((text) => {
                                      return {
                                        label: text.name,
                                        value: text.id,
                                        id: text.id,
                                      };
                                    })}
                                    onChange={(e, { name }) => {
                                      handleDataChange(e, { name });
                                      setviewfileds(true);
                                      setviewselectdatasample(false);
                                    }}
                                    placeholder=""
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col md={12} className="text-center">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={() => {
                                    setviewfileds(true);
                                    setviewselectdatasample(false);
                                  }}
                                >
                                  <i className="mdi mdi-pencil-circle" /> Manual
                                  Fill
                                </Button>
                              </Col>
                            </Row>
                          )}

                          {viewfileds === true && (
                            <span>
                              <Row>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField
                                        name="name"
                                        label="Sample Name"
                                        placeholder="Enter sample name"
                                        type="text"
                                        errorMessage="Please enter sample name"
                                        validate={{ required: { value: true } }}
                                        onChange={handleChange}
                                        value={contactDetails?.name || ""}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label className="form-label">Type</Label>
                                      <Select
                                        defaultValue={{
                                          label: containerTypeList.filter(
                                            (c) =>
                                              c.id === contactDetails.select
                                          )?.[0]?.name,
                                          value: containerTypeList.filter(
                                            (c) =>
                                              c.id === contactDetails.select
                                          )?.[0]?.id,
                                        }}
                                        value={{
                                          label: containerTypeList.filter(
                                            (c) =>
                                              c.id === contactDetails.select
                                          )?.[0]?.name,
                                          value: containerTypeList.filter(
                                            (c) =>
                                              c.id === contactDetails.select
                                          )?.[0]?.id,
                                        }}
                                        validate={{ required: { value: true } }}
                                        name="select"
                                        options={containerTypeList?.map(
                                          (text) => {
                                            return {
                                              label: text.name,
                                              value: text.id,
                                            };
                                          }
                                        )}
                                        onChange={handleChange}
                                        placeholder="Select"
                                        classNamePrefix="select2-selection"
                                        required
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <AvField
                                        name="hsa_code"
                                        label="HSA Code"
                                        placeholder="Enter HSA code"
                                        type="text"
                                        errorMessage="Please enter HSA code"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={handleChange}
                                        value={contactDetails?.hsa_code || ""}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <AvField
                                      name="unit"
                                      label="Unit"
                                      placeholder="Enter Unit"
                                      type="text"
                                      errorMessage="Please enter Unit"
                                      validate={{
                                        required: { value: false },
                                      }}
                                      onChange={handleChange}
                                      value={contactDetails?.unit || ""}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField
                                        name="SMDC"
                                        label={
                                          <div className={"d-block"}>
                                            MSDS{" "}
                                            <Link
                                              to={contactDetails?.SMDC}
                                              target="_blank"
                                              className={
                                                contactDetails.SMDC
                                                  ? "align-middle mx-3 d-inline-block"
                                                  : "d-none"
                                              }
                                            >
                                              <i
                                                className={
                                                  "ri ri-lg ri-download-2-fill"
                                                }
                                              ></i>{" "}
                                              <span className={"small"}>
                                                DOWNLOAD
                                              </span>
                                            </Link>
                                          </div>
                                        }
                                        labelClassName={"w-100 d-block"}
                                        placeholder="Upload a File"
                                        type="file"
                                        accept=".pdf"
                                        errorMessage={
                                          contactDetails.select !== 4 &&
                                          isEdit === "Add"
                                            ? "Upload a File"
                                            : ""
                                        }
                                        {...(contactDetails.select !== 4 &&
                                          isEdit === "Add" && {
                                            validate: {
                                              required: { value: true },
                                            },
                                          })}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <AvField
                                        name="usage"
                                        label="Usage"
                                        placeholder="Enter Usage"
                                        type="text"
                                        errorMessage="Please enter usage"
                                        validate={{ required: { value: true } }}
                                        onChange={handleChange}
                                        value={contactDetails?.usage || ""}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Authority
                                      </Label>
                                      <Select
                                        value={authority.filter((c) => {
                                          return (
                                            c.value === contactDetails.authority
                                          );
                                        })}
                                        validate={{ required: { value: true } }}
                                        name="authority"
                                        options={authority}
                                        onChange={handleChange}
                                        classNamePrefix="select3-selection"
                                        required
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <hr style={{ margin: "20px 0px" }} />

                                <Col md={4} xs={6}>
                                  <h5 className="font-size-18 mb-1 text-truncate">
                                    Components
                                  </h5>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={addItem}
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add
                                      New
                                    </Button>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="name"
                                      className="form-label"
                                    >
                                      Component Name
                                    </label>
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cas_code"
                                      className="form-label"
                                    >
                                      CAS Number
                                    </label>
                                  </div>
                                </Col>
                                <Col md={2}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cas_code"
                                      className="form-label"
                                    >
                                      Percentage
                                    </label>
                                  </div>
                                </Col>

                                {items.map((d, index) => {
                                  return (
                                    <Row
                                      style={{ alignItems: "center" }}
                                      key={index}
                                    >
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <AvField
                                            name={`name${d.id}`}
                                            placeholder="Enter component name"
                                            type="text"
                                            errorMessage="Please enter component name"
                                            onChange={(e) =>
                                              handleUserInput(e, d.id)
                                            }
                                            value={d[`name${d.id}`]}
                                            {...(contactDetails.select !==
                                              4 && {
                                              validate: {
                                                required: { value: true },
                                              },
                                            })}
                                          />
                                        </div>
                                      </Col>

                                      <Col md={4}>
                                        <div className="mb-3">
                                          <AvField
                                            name={`cas_code${d.id}`}
                                            placeholder="Enter CAS Number"
                                            type="text"
                                            errorMessage="Please enter CAS Number"
                                            onChange={(e) => {
                                              handleUserInput(e, d.id);
                                              search(e, d.id);
                                            }}
                                            {...(contactDetails.select !==
                                              4 && {
                                              validate: {
                                                required: { value: true },
                                              },
                                            })}
                                            // onChange={handleUserInput}
                                            value={d[`cas_code${d.id}`]}
                                          />
                                        </div>
                                        {searchedCasNumber?.length > 0 &&
                                          d.flag && (
                                            <div className="card">
                                              <Dropdown
                                                isOpen={dropdownFlag}
                                                toggle={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                                <DropdownToggle
                                                  color="info"
                                                  caret
                                                  style={{ display: "none" }}
                                                >
                                                  Dropdown button{" "}
                                                  <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                  style={{
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                  }}
                                                >
                                                  {searchedCasNumber.map(
                                                    (data, i) => {
                                                      return (
                                                        <DropdownItem
                                                          key={i}
                                                          onClick={(e) => {
                                                            chooseDataComp(
                                                              data,
                                                              d.id
                                                            );
                                                            setDropdownFlag(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {data.cas_code}
                                                        </DropdownItem>
                                                      );
                                                    }
                                                  )}
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                          )}
                                      </Col>
                                      <Col md={3}>
                                        <div className="mb-3">
                                          <AvField
                                            name={`percentage${d.id}`}
                                            placeholder="Percentage"
                                            type="text"
                                            errorMessage="Please enter Percentage"
                                            onChange={(e) =>
                                              handleUserInput(e, d.id)
                                            }
                                            {...(contactDetails.select !==
                                              4 && {
                                              validate: {
                                                required: { value: true },
                                              },
                                            })}
                                            // onChange={handleUserInput}
                                            value={d[`percentage${d.id}`]}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={1}>
                                        <div className="text-sm-end">
                                          {items.length > 1 && (
                                            <div
                                              type="button"
                                              color="success"
                                              className="text-danger"
                                              onClick={() =>
                                                removeItem(d.id, d.type)
                                              }
                                            >
                                              <i className="mdi mdi-delete font-size-18" />
                                            </div>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Row>
                            </span>
                          )}
                        </Row>
                        {viewfileds && (
                          <FormGroup
                            className="mb-0"
                            style={{ float: "right" }}
                          >
                            <div>
                              <Button
                                type="submit"
                                color="info"
                                className="me-1"
                              >
                                {isEdit === "Add" ? "Save" : "Update"}
                              </Button>{" "}
                              <Button
                                type="reset"
                                color="secondary"
                                onClick={modalClose}
                              >
                                Cancel
                              </Button>
                            </div>
                          </FormGroup>
                        )}
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={4}>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Label
                                      htmlFor="shipment-date-input"
                                      className="form-label"
                                    >
                                      Search
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={(e) => onSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    // sort={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataSampleList;
