import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class PieChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: this.props?.data,
            options : {
                labels:this.props?.label ,
                colors: this.props?.colors,
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]; // Display numbers without decimal places
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        colors: ['#ffffff']
                    }
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        },
                    }
                }],              
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={this.state.series} type="pie" height="320" />
            </React.Fragment>
        );
    }
}

export default PieChart;