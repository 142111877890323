import React, { useEffect, useState } from "react";
import {
  Container,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import MiniWidgets from "./MiniWidgets";
import "../../Common/datatables.scss";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../../config/constants";
import ShipmentTable from "../../CommonComponents/ShipmentTable";
import PieChart from "../Charts/PieChart";
import ApiUtils from "../../../api/ApiUtils";
import separateValues from "../../../helper/separateValue";
import { useUserRole } from "../../../helper/UserProvider";
import { ToasterError } from "../../../helper/ToasterHelper";

function Dashboard() {
  const { userRole } = useUserRole();
  const [dashboardCount, setDashboadCount] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN ? true : false;
  const isUserTypeSampler =
    userRole.role_id === USER_TYPE.SAMPLER ? true : false;
  console.log(isUserTypeSampler, userRole);
  const [breadcrumbItems] = useState([]);

  const fetchDashBoardCount = async () => {
    await ApiUtils.getShipmentDashboardCount()
      .then((data) => {
        setDashboadCount(data?.data?.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };
  const navigate = useNavigate();

  const fetchShipmentChartData = async () => {
    await ApiUtils.getShipmentChart()
      .then((data) => {
        setShipmentData(data?.data?.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };
  const fetchPaymentChartData = async () => {
    await ApiUtils.getPaymentChart()
      .then((data) => {
        setPaymentData(data?.data?.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const { data: shipValue, label: shimentLabel } = separateValues(shipmentData);
  const { data: paymentValue, label: paymentLabel } =
    separateValues(paymentData);

  useEffect(() => {
    fetchDashBoardCount();
    fetchShipmentChartData();
    fetchPaymentChartData();
  }, []);

  const reports = [
    {
      icon: "ri-ship-line",
      title: "Number of Shipments",
      value: dashboardCount?.shipment_count,
    },
    {
      icon: "ri-stack-line",
      title: "Number of Containers",
      value: dashboardCount?.ship_container_count,
    },
    {
      icon: "ri-team-line",
      title: "Number of Users",
      value: dashboardCount?.user_count,
    },
    {
      icon: "ri-building-4-line",
      title: "Number of clients",
      value: dashboardCount?.client_contact_count,
    },
  ];
  const [search, setsearch] = useState("");

  const searchHandleChange = async () => {
    await ApiUtils.getShipmentList(
      `search=${search}&sort_column=id&sort_direction=asc&page_size=10&page=1`
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);

          const id = res?.data?.data?.data[0]?.id;
          //here will ttake the item id
          if (id) {
            onPreviewShipmentPull(id);
          } else {
            ToasterError("Shipment CDN not Found");
          }
        }
      })
      .catch((err) => {
        ToasterError("Shipment CDN not Found");
      });
  };
  const onPreviewShipmentPull = (id) => {
    navigate(`/pull-shipment-samples/${id}`);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {!isUserTypeSampler && (
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />

            <Row>
              <MiniWidgets reports={reports} />
            </Row>

            {isUserTypeAdmin && (
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Shipment</CardTitle>
                      {shipValue?.length > 0 && (
                        <PieChart
                          data={shipValue}
                          label={shimentLabel}
                          colors={[
                            "#34c38f",
                            "#556ee6",
                            "#f46a6a",
                            "#50a5f1",
                            "#f1b44c",
                          ]}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Invoice</CardTitle>
                      {paymentValue?.length && (
                        <PieChart
                          data={paymentValue}
                          label={paymentLabel}
                          colors={["#34c38f", "#f1b44c"]}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            <ShipmentTable updateDashboardCount={fetchDashBoardCount} />
          </Container>
        )}

        {isUserTypeSampler && (
          <Container
            style={{ height: "70vh" }}
            className="d-flex flex-column  justify-content-center"
          >
            <Row className="mb-2 mt-2">
              <Col md={12}>
                <div className="search-box  d-inline-block w-100">
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search For Pull Sample By CDN"
                      value={search}
                      onChange={(e) => {
                        setsearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={4} />
              <Col
                md={4}
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  className="bg-info mt-4"
                  block
                  style={{ border: "none" }}
                  onClick={() => searchHandleChange()}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
