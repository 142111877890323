import { AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  Container,
  CardTitle,
  CardText,
  CardBody,
  Label,
  Input,
  Button,
} from "reactstrap";
import ApiUtils from "../../api/ApiUtils";
import { convertAmountToWords } from "../../helper/NumberToWords";
import { useNavigate, useParams } from "react-router-dom";
import { ToasterError, ToasterSuccess } from "../../helper/ToasterHelper";

function LabResult(){
  const [shipmentData, setShipmentData] = useState([]);
  const [componentDefinition, setComponentDefinition] = useState([]);
  const [containers, setContainers] = useState([shipmentData.ship_container]);
  const searchParam = useParams();
  const navigate = useNavigate();

  const optionResult = [
    { label: "Approved", value: 1 },
    { label: "Not Approved", value: 2 },
  ];

  async function getList() {
    await ApiUtils.getShipmentById(`id=${searchParam?.id}`)
        .then((res) => {
          if (res.status === 200) {
            res?.data?.data?.ship_container.forEach((val) => {
              val.data_sample.flatMap((sample) => {
                sample.data_companent.map((component) => {
                  if (!componentDefinition.some(obj => obj.id === component.id)) {
                    componentDefinition.push({
                      id: component.id,
                      name: component.name,
                      cas_code: component.cas_code,
                    });
                  }
                });
              });
              setComponentDefinition(componentDefinition);
            });
            setShipmentData(res?.data?.data);
            const containers = [...res.data.data.ship_container];
            setContainers(containers);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
  }

  useEffect(() => {
    getList();
    if (!searchParam?.id) {
      navigate("/shipment-report");
    }
  }, []);

  const handleChange = (e, container, containerIdx, name=null, val=null) => {
    const containers = [...shipmentData.ship_container];
    containers[containerIdx][name??e.target.name] = val??e.target.value;
    setContainers(containers);
  };

  const handleFileSelect = (e) => {
    let containersState = containers?.map((item) => {
      // Check if item.attachment_files is iterable (an array in this case)
      // If not, initialize it as an empty array
      const attachmentFiles = Array.isArray(item.attachment_files) ? item.attachment_files : [];

      if (Number(item.ship_data_sample_id) === Number(e.target.id)) {
        const filesArray = Array.from(e.target.files);
        const newFiles = filesArray.map((file) => file); // This map operation is redundant, could directly use filesArray

        // Use the verified or initialized attachmentFiles array
        return {
          ...item,
          attachment_files: [...attachmentFiles, ...newFiles],
        };
      } else {
        return { ...item };
      }
    });
    setContainers(containersState);
  };


  // ***************************************
  // HANDLE LAB ANALYSIS SUBMISSION TO KEPA
  // ***************************************
  const handleSubmit = (data,containers) => {

    const formData = new FormData();
    formData.append("ship_id", shipmentData.id);
    formData.append("ship_container_id", data.id);
    let count = 0;
    console.log(data,containers)
    // Check if data.attachment_files is defined and has at least one element
    if (containers[count].attachment_files && containers[count].attachment_files.length > 0) {
      console.log('in if condition submit',containers[count].attachment_files)
      formData.append(
          `ship_lab_result_file[${count}][ship_data_sample_id]`,
          data.ship_data_sample_id ? data.ship_data_sample_id : ""
      );
      formData.append(
          `ship_lab_result_file[${count}][ship_container_number]`,
          data.container_number ? data.container_number : ""
      );
      formData.append(
          `ship_lab_result_file[${count}][file]`,
          containers[count].attachment_files[0]
      );
    }

    // It seems like these fields are always appended regardless of the attachment_files check
    // Ensure that these fields should indeed always be appended
    formData.append(`data_component[${count}][id]`, data.test_component_id);
    formData.append(`data_component[${count}][test_type]`, data.test_type);
    formData.append(`data_component[${count}][test_method]`, data.test_method);
    formData.append(`data_component[${count}][test_unit]`, data.test_unit);
    formData.append(`data_component[${count}][test_result]`, data.test_result);
    formData.append(`data_component[${count}][status]`, data.test_status);

    count++;
    console.log(formData)
    ApiUtils.updateDataComponent(formData)
        .then((res) => {
          if (res.status === 200) {
            ToasterSuccess(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
  }


  const handleComponent = (
      selectedOption,
      data_companent,
      index,
      ship_data_sample_id,
      number_of_container
  ) => {

    let updatedComp = containers;
    setContainers(updatedComp);
  };

  const invoiceTotalAmountInWords = convertAmountToWords(
      shipmentData?.tota_amount
  );

  return (
      <React.Fragment>
        <div className="page-content" id="invoice-preview">
          <Container fluid={true}>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Lab Test Result</h4>
                </div>
              </Col>
            </Row>
            <Card style={{ paddingTop: "20px" }}>
              <CardBody>
                <div className="row justify-content-center">
                  <Row>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Shipment Date
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData.ship_date}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Customs Declaration No.
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData.customs_decleration_number}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Shipment Status
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.status}
                        </CardText>
                      </Card>
                    </Col>{" "}
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Shipment Type
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.ship_type === 1
                              ? "Import"
                              : shipmentData?.ship_type === 2
                                  ? "Export"
                                  : ""}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Content Type
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.container_type?.map((text, i) => {
                            return (
                                <span
                                    key={i}
                                    style={{ textTransform: "capitalize" }}
                                >
                              {text?.name}
                            </span>
                            );
                          })}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          AWB Number
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.awb_number}
                        </CardText>
                      </Card>
                    </Col>{" "}
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Card body className="card-body-new-shipment">
                        <div className="d-flex align-items-center">
                          <div className="left-content">
                            <CardTitle className="h4 card-title-new-shipment strait-line">
                              {shipmentData?.ship_type === 1 ? "Import" : "Export"}
                              Company
                            </CardTitle>
                            <CardText className="card-text-new-shipment strait-line">
                              {shipmentData?.client_contact?.map((text, i) => {
                                return (
                                    <React.Fragment key={i}>
                                      <span>{text?.company_name}</span>
                                    </React.Fragment>
                                );
                              })}
                            </CardText>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="right-content d-flex flex-column">
                            <CardTitle className="h4 card-title-new-shipment">
                              Contact Number
                            </CardTitle>
                            <CardText className="card-text-new-shipment">
                              {shipmentData?.client_contact?.map((text, i) => {
                                return (
                                    <React.Fragment key={i}>
                                      <span>{text?.mobile_number}</span>
                                    </React.Fragment>
                                );
                              })}
                            </CardText>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card body className="card-body-new-shipment">
                        <div className="d-flex align-items-center">
                          <div className="left-content">
                            <CardTitle className="h4 card-title-new-shipment strait-line">
                              Customs Broker
                            </CardTitle>
                            <CardText className="card-text-new-shipment strait-line">
                              {shipmentData?.broker?.map((text, i) => {
                                return (
                                    <React.Fragment key={i}>
                                      <span>{text?.name}</span>
                                    </React.Fragment>
                                );
                              })}
                            </CardText>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="right-content d-flex flex-column">
                            <CardTitle className="h4 card-title-new-shipment">
                              Contact Number
                            </CardTitle>
                            <CardText className="card-text-new-shipment">
                              {shipmentData?.broker?.map((text, i) => {
                                return (
                                    <React.Fragment key={i}>
                                      <span>{text?.mobile_number}</span>
                                    </React.Fragment>
                                );
                              })}
                            </CardText>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Number of Containers
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {!shipmentData?.total_container
                              ? 0
                              : shipmentData?.total_container}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Chemical Containers
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {!shipmentData?.chemical_container
                              ? 0
                              : shipmentData?.chemical_container}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Physical Containers
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {!shipmentData?.physical_container
                              ? 0
                              : shipmentData?.physical_container}
                        </CardText>
                      </Card>
                    </Col>{" "}
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Port Name
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.port?.map((text, i) => {
                            return <span key={i}>{text?.name}</span>;
                          })}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          {shipmentData?.ship_type === 1 ? "Import" : "Export"}{" "}
                          Country
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.importing_exporting_country?.map(
                              (text, i) => {
                                return <span key={i}>{text.name}</span>;
                              }
                          )}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Manufacturing Company Name
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.manufacturing_company_name}
                        </CardText>
                      </Card>
                    </Col>{" "}
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Origin Country
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.origin_country?.map((text, i) => {
                            return <span key={i}>{text?.name}</span>;
                          })}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Origin Certificate No.
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.origin_certificate_number}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment">
                          Shipment Invoice #.
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.invoice_number}
                        </CardText>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                          Invoice No.
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.invoice?.map((text, i) => {
                            return <span key={i}>{text?.id}</span>;
                          })}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                          Invoice Total
                        </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentData?.invoice?.map((text, i) => {
                            return (
                                <span key={i}>
                              {parseInt(text?.total_amount)} KWD
                            </span>
                            );
                          })}
                        </CardText>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card body className="card-body-new-shipment">
                        <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                          Total Amount in Words
                        </CardTitle>
                        <CardText
                            className="card-text-new-shipment"
                            style={{ textTransform: "capitalize" }}
                        >
                          {invoiceTotalAmountInWords &&
                              `${invoiceTotalAmountInWords} Kuwaiti Dinar`}
                        </CardText>
                      </Card>
                    </Col>{" "}
                  </Row>
                </div>

                {shipmentData?.ship_container?.map((data, index) => {
                  if (data.data_sample.length !== 0) {
                    return (
                        <div key={data.id}>
                          <Row>
                            <Col lg="12">
                              <AvForm>
                                <Row>
                                  <Col md="12" className="my-3">
                                    <div className="custom-card">
                                      <div className="card-header">
                                        <div
                                            className="mb-4"
                                            style={{
                                              display: "flex",
                                              gap: "50px",
                                              alignItems: "center",
                                              justifyContent: "space-around",
                                            }}
                                        >
                                          <div>
                                            <Label
                                                className="form-label"
                                                htmlFor="upload-samples"
                                            >
                                              <CardTitle className="fw-bold">
                                                Container Number
                                              </CardTitle>
                                            </Label>
                                            <CardTitle className="fs-6">
                                              <div className="badge bg-info font-size-16">
                                                {data?.number}
                                              </div>
                                            </CardTitle>
                                          </div>
                                          <div>
                                            <Label
                                                className="form-label"
                                                htmlFor="upload-samples"
                                            >
                                              <CardTitle className="fw-bold">
                                                Data Sample
                                              </CardTitle>
                                            </Label>
                                            <CardTitle className="fs-6">
                                              <div className="badge bg-dark font-size-16">
                                                {data?.data_sample_name}
                                              </div>
                                            </CardTitle>
                                          </div>
                                          <div>
                                            <Label
                                                className="form-label"
                                                htmlFor="upload-samples"
                                            >
                                              <CardTitle className="fw-bold">
                                                HS Code
                                              </CardTitle>
                                            </Label>
                                            <CardTitle className="fs-6">
                                              {data?.hsa_code}
                                            </CardTitle>
                                          </div>

                                          <Input type="hidden" name={`component-${data.id}`} value={1}/>

                                          <div className="justify-content-end">
                                            <Label
                                                className="form-label"
                                                htmlFor="upload-samples"
                                            >
                                              <CardTitle className="fw-bold">
                                                Select Attachment
                                              </CardTitle>
                                            </Label>
                                            <Input
                                                type="file"
                                                name={`upload-file-${data.ship_data_sample_id}`}
                                                multiple
                                                id={data.ship_data_sample_id}
                                                onChange={(e) => handleFileSelect(e)}
                                            />
                                          </div>

                                          {shipmentData.ship_data_sample
                                              .filter(
                                                  (d) =>
                                                      d.data_sample_id ===
                                                      data.ship_data_sample_id
                                              )
                                              .map(
                                                  (d, index) =>
                                                      d.lab_result_file && (
                                                          <div
                                                              className="mt-4 ml-5"
                                                              key={index}
                                                          >
                                                            <a
                                                                href={d.lab_result_file}
                                                                download
                                                                target="_blank"
                                                            >
                                                              <i className="ri-download-2-fill font-size-40 download-btn-inner"></i>
                                                            </a>
                                                          </div>
                                                      )
                                              )}
                                        </div>
                                      </div>

                                      <div className="card-body">
                                        <Row>
                                          <Col>
                                            <Label className="form-label">
                                              Component Name
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label className="form-label">CAS</Label>
                                          </Col>
                                          <Col>
                                            <Label className="form-label">
                                              Test Type
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label className="form-label">
                                              Test Method
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label className="form-label">
                                              Test Unit
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label className="form-label">
                                              Test Result
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label className="form-label">
                                              Status
                                            </Label>
                                          </Col>
                                        </Row>

                                        <Row className="mb-3">
                                          <Col>
                                            <Select
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        data,
                                                        index,
                                                        "test_component_id",
                                                        e.value
                                                    )
                                                }
                                                name="test_component_id"
                                                style={{textTransform: "capitalize"}}
                                                options={data.data_sample.flatMap(
                                                    (i) =>
                                                        i.data_companent.map(
                                                            (c) => ({
                                                              value: c.id,
                                                              label: c.name
                                                            })
                                                        )
                                                )}

                                                value={{
                                                  label: componentDefinition.find(obj => obj.id === data.test_component_id)?.name,
                                                  value: data.test_component_id
                                                }}
                                            />
                                          </Col>
                                          <Col>
                                            <Input
                                                type="text"
                                                name={`cas-code-${data.id}`}
                                                style={{textTransform: "capitalize"}}
                                                className="form-control"
                                                value={componentDefinition.find(obj => obj.id === data.test_component_id)?.cas_code}
                                                readOnly
                                            />
                                          </Col>

                                          <Col>
                                            <div>
                                              <Input
                                                  type="text"
                                                  name="test_type"
                                                  style={{textTransform: "capitalize"}}
                                                  value={data.test_type}
                                                  className="form-control"
                                                  onChange={(e) =>
                                                      handleChange(
                                                          e,
                                                          data,
                                                          index
                                                      )
                                                  }
                                              />
                                            </div>
                                          </Col>

                                          <Col>
                                            <Input
                                                type="text"
                                                name="test_method"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                value={data.test_method}
                                                className="form-control"
                                                onChange={(e) =>
                                                    handleChange(e, data, index)
                                                }
                                            />
                                          </Col>

                                          <Col>
                                            <Input
                                                type="text"
                                                name="test_unit"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                value={data.test_unit}
                                                className="form-control"
                                                onChange={(e) =>
                                                    handleChange(e, data, index)
                                                }
                                            />
                                          </Col>

                                          <Col>
                                            <Input
                                                type="text"
                                                name="test_result"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                value={data.test_result}
                                                className="form-control"
                                                onChange={(e) =>
                                                    handleChange(e, data, index)
                                                }
                                            />
                                          </Col>

                                          <Col>
                                            <Select
                                                name="status"
                                                options={optionResult.map(
                                                    (or) => ({
                                                      ...or,
                                                      name: `status`,
                                                    })
                                                )}
                                                classNamePrefix="select2-selection"
                                                placeholder="Select Result"
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        data,
                                                        index,
                                                        "test_status",
                                                        e.value
                                                    )
                                                }
                                                value={optionResult.filter(
                                                    (opt) =>
                                                        opt.value === data.test_status
                                                )}
                                            />
                                          </Col>

                                        </Row>


                                        <div className="d-flex justify-content-center mt-4">
                                          <Button
                                              type="button"
                                              color="info"
                                              style={{position: "inherit"}}
                                              onClick={() =>
                                                  handleSubmit(data, containers)
                                              }
                                          >
                                            SEND RESULTS TO KEPA
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </Col>
                          </Row>
                        </div>
                    );
                  }
                })}
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default LabResult;
