import React, { useState } from "react";

import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import { USER_TYPE } from "../../config/constants";
import { useUserRole } from "../../helper/UserProvider";

const Layout = (props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleRightSidebar = () => {
    // Function logic goes here
  };

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };
  const { userRole } = useUserRole();
  const isUserTypeSampler = userRole.role_id === USER_TYPE.SAMPLER;

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          // theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          toggleRightSidebar={toggleRightSidebar}
          openLeftMenuCallBack={openMenu}
        />
        {!isUserTypeSampler && (
          <Navbar menuOpen={isMenuOpened} openLeftMenuCallBack={openMenu} />
        )}
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
