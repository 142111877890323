import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import swal from "sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../api/ApiUtils";

import {
  ToasterError,
  ToasterSuccess,
  ToasterWarning,
} from "../helper/ToasterHelper";
import { useCallback } from "react";
function BrokersList() {
  const [modalCenter, setModalCenter] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [editContactId, setEditContactId] = useState("");
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    mobile_number: "",
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");

  const togCenter = () => {
    setModalCenter(!modalCenter);
    removeBodyCss();
    setContactDetails({
      name: "",
      email: "",
      mobile_number: "",
    });
  };

  const fetchBrokersList = async () => {
    await ApiUtils.getBrokersList(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res?.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setBrokers(res ? res.data.data.data : []);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getBrokersList(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setBrokers(res ? res.data.data.data : []);
        }
      })
      .catch((err) => setBrokers([]));
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const sortBrokersListFunc = async (field, order) => {
    await ApiUtils.getBrokersList(
      `search=${searchValue}&sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setBrokers(res ? res.data.data.data : []);
        }
      })
      .catch((err) => setBrokers([]));
  };

  useEffect(() => {
    fetchBrokersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const editContact = async (data) => {
    removeBodyCss();
    let updatedUserData = {
      name: data?.name,
      email: data?.email,
      mobile_number: data?.mobile_number,
    };
    setEditContactId(data.id);
    await setContactDetails(updatedUserData);
    await setIsEditMode(true);
    await setModalCenter(!modalCenter);
  };
  const deleteContact = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete Broker?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApiUtils.deleteBroker(data.id)
          .then((res) => {
            if (res.status === 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setCurrentPage(1);
              fetchBrokersList();
            }
          })
          .catch((error) => {
            if (error && error.data && error.data.message) {
              ToasterError(error.data.message);
            } else if (error && error.data) {
              ToasterError(error.data);
            } else if (error) {
              ToasterError(error);
            }
          });
      } else {
        swal({ title: "Broker not deleted!", icon: "error" });
      }
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortBrokersListFunc(field, order);
      },
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortBrokersListFunc(field, order);
      },
    },

    {
      dataField: "mobile_number",
      text: "Mobile Number",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortBrokersListFunc(field, order);
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      formatter: (_cellContent, row) => (
        <>
          <UncontrolledDropdown className="text-center">
            <DropdownToggle tag="i" className="arrow-none">
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => editContact(row)}
              >
                <i className="mdi mdi-pencil font-size-18"></i>
                Update
              </DropdownItem>

              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => deleteContact(row)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const handleUserInput = (e) => {
    setContactDetails({
      ...contactDetails,
      [e?.target?.name]: e?.target?.value,
    });
  };
  const modalClose = () => {
    setModalCenter(false);
    setIsEditMode(false);
    setEditContactId("");
    setContactDetails({
      name: "",
      email: "",
      mobile_number: "",
    });
  };

  const onSubmitUser = async (_e, error, _values) => {
    if (error?.length > 0) {
      ToasterWarning("Please fill all the input field");
    } else {
      const body = {
        id: editContactId,
        name: contactDetails.name,
        email: contactDetails.email,
        mobile_number: contactDetails.mobile_number,
      };

      performOperation(isEditMode, isEditMode ? body : contactDetails)
        .then((res) => {
          if (res.status === 200) {
            fetchBrokersList();
            setModalCenter(false);

            setTimeout(() => {
              setContactDetails({
                name: "",
                email: "",
                mobile_number: "",
              });
            }, 100);

            setEditContactId("");
            setIsEditMode(false);
            ToasterSuccess(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const performOperation = (checkMode, params) =>
    !checkMode ? ApiUtils.createBroker(params) : ApiUtils.updateBroker(params);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Brokers List</h4>

                <div className="page-title-right">
                  <div className="my-4 text-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light"
                      onClick={togCenter}
                    >
                      Add Brokers
                    </Button>
                  </div>

                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop="static"
                  >
                    <ModalHeader toggle={modalClose}>
                      {isEditMode ? "Edit Broker" : "Add Broker"}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm onSubmit={onSubmitUser}>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="name"
                                label="Name"
                                placeholder="Enter Broker name"
                                type="text"
                                errorMessage="Please enter broker name"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.name}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="mobile_number"
                                label="Mobile Number"
                                placeholder="Enter mobile number"
                                type="number"
                                errorMessage={{
                                  required: "Please enter mobile number",

                                }}
                                validate={{
                                  required: { value: true },

                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.mobile_number}

                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email Address"
                                placeholder="Enter Email Address"
                                type="email"
                                errorMessage="Please enter email address"
                                validate={{
                                  required: { value: true },
                                  email: { value: true },
                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.email}
                              />
                            </div>
                          </Col>
                        </Row>

                        <FormGroup className="mb-0" style={{ float: "right" }}>
                          <div>
                            <Button
                              type="submit"
                              color="info"
                              className="me-1"
                            >
                              {isEditMode ? "Update" : "Save"}
                            </Button>{" "}
                            <Button
                              type="reset"
                              color="secondary"
                              onClick={modalClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={brokers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={brokers}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={4}>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Label
                                      htmlFor="shipment-date-input"
                                      className="form-label"
                                    >
                                      Search
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={(e) => onSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BrokersList;
