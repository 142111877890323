import React, { useEffect } from "react";
import "../assets/custom/custom2.css";
import NewHeader from "./newHeader";
import { useLocation, useNavigate } from "react-router-dom";
import ApiUtils from "../api/ApiUtils";
import { Row, Col, Card, CardBody } from "reactstrap";
import logo from "../assets/images/MEL-LOGO.png";
import { useState } from "react";
import { ToasterError } from "../helper/ToasterHelper";

const Success = () => {
  const [details, setDetails] = useState([]);
  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const ID = searchParams.get("id");
  const txnId = searchParams.get("txnId");
  const errorCode = searchParams.get("errorCode");

  const navigate = useNavigate();

  useEffect(() => {
    const data = {
      id: ID,
      txn_id: txnId,
      error_code: Number(errorCode),
    };
    if (search) {
      ApiUtils.createPaymentDetail(data)
        .then((res) => {
          if (res.status === 200) {
            setDetails(res?.data?.data);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NewHeader />
      <Row className="col-12">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <Card style={{ width: "50%" }}>
            <CardBody>
              <div style={{ textAlign: "center" }}>
                <img src={logo} alt="Logo" />
              </div>
              <hr />

              <Row>
                <Col>
                  <h2
                    className="mb-5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      color: "green",
                    }}
                  >
                    PAYMENT SUCCESSFUL
                  </h2>
                </Col>
              </Row>

              <Row className="justify-content-between">
                <Col>
                  <h5>Client Name:</h5>
                  <p>{details.client_name}</p>
                </Col>
                <Col>
                  <h5>Invoice Number:</h5>
                  <p>{details.invoice_number}</p>
                </Col>
                <Col className="text-end" style={{ whiteSpace: "nowrap" }}>
                  <h5>Invoice Date and Time:</h5>
                  <p>{details.invoice_date_and_time}</p>
                </Col>
              </Row>
              <Row className="justify-content-between mt-4">
                <Col>
                  <h5>CDN:</h5>
                  <p>{details.customs_decleration_number}</p>
                </Col>
                <Col>
                  <h5>Total Amount:</h5>
                  <p>{details.total_amount}</p>
                </Col>
                <Col className="text-end">
                  <h5>Track ID:</h5>
                  <p>{details.txn_id}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default Success;
