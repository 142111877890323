import React, { useCallback, useEffect, useState } from "react";

import {
  Row,
  Col,
  TabPane,
  Label,
  Button,
  FormGroup,
  Input,
  Table,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import ApiUtils from "../../../api/ApiUtils";
import {
  ToasterError,
  ToasterSuccess,
  ToasterWarning,
} from "../../../helper/ToasterHelper";
import { formToJSON } from "axios";
function Step3({
  tabId,
  clickedNextTab,
  setActiveTabProgress,
  setClickedNextTab,
  setProgressValue,
  progressValue,
  allShipmentDetails,
  searchShipmentId,
}) {
  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const shipmentIDNumber = parseInt(shipmentData?.noOfContainer);
  const [searchedSampleData, setSearchedSampleData] = useState([]);
  const [containerNumberText, setContainerNumberText] = useState(() => {
    if (shipmentIDNumber === allShipmentDetails?.ship_container?.length) {
      return allShipmentDetails?.ship_container?.map((data) => ({
        id: data?.id,
        value: data?.number,
        type: data?.container_type_id,
        isIdRequired: true,
      }));
    } else if (allShipmentDetails?.ship_container?.length > 0) {
      return Array.from({ length: shipmentIDNumber }, (x, i) => {
        if (i < allShipmentDetails?.ship_container?.length) {
          return {
            id: allShipmentDetails.ship_container[i]?.id,
            value: allShipmentDetails.ship_container[i]?.number,
            type: allShipmentDetails.ship_container[i]?.container_type_id,
            isIdRequired: true,
          };
        } else {
          return {
            id: i + 1,
            value: "",
            type:
              allShipmentDetails.content_type_id === 3
                ? i < allShipmentDetails.physical_container
                  ? 2
                  : 1
                : allShipmentDetails.content_type_id === 5
                ? i < allShipmentDetails.physical_container
                  ? 2
                  : 1
                : allShipmentDetails.content_type_id,
            isIdRequired: false,
          };
        }
      });
    } else {
      return Array.from({ length: shipmentIDNumber }, (x, i) => ({
        id: i + 1,
        value: "",
        type:
          allShipmentDetails.content_type_id === 3
            ? i < allShipmentDetails.physical_container
              ? 2
              : 1
            : allShipmentDetails.content_type_id === 5
            ? i < allShipmentDetails.physical_container
              ? 2
              : 1
            : allShipmentDetails.content_type_id,
        isIdRequired: false,
      }));
    }
  });
  useEffect(() => {
    // Perform your action here
    setContainerNumberText(() => {
      if (shipmentIDNumber === allShipmentDetails?.ship_container?.length) {
        return allShipmentDetails?.ship_container?.map((data) => ({
          id: data?.id,
          value: data?.number,
          type: data?.container_type_id,
          isIdRequired: true,
        }));
      } else if (allShipmentDetails?.ship_container?.length > 0) {
        return Array.from({ length: shipmentIDNumber }, (x, i) => {
          if (i < allShipmentDetails?.ship_container?.length) {
            return {
              id: allShipmentDetails.ship_container[i]?.id,
              value: allShipmentDetails.ship_container[i]?.number,
              type: allShipmentDetails.ship_container[i]?.container_type_id,
              isIdRequired: true,
            };
          } else {
            return {
              id: i + 1,
              value: "",
              type:
                allShipmentDetails.content_type_id === 3
                  ? i < allShipmentDetails.physical_container
                    ? 2
                    : 1
                  : allShipmentDetails.content_type_id === 5
                  ? i < allShipmentDetails.physical_container
                    ? 2
                    : 1
                  : allShipmentDetails.content_type_id,
              isIdRequired: false,
            };
          }
        });
      } else {
        return Array.from({ length: shipmentIDNumber }, (x, i) => ({
          id: i + 1,
          value: "",
          type:
            allShipmentDetails.content_type_id === 3
              ? i < allShipmentDetails.physical_container
                ? 2
                : 1
              : allShipmentDetails.content_type_id === 5
              ? i < allShipmentDetails.physical_container
                ? 2
                : 1
              : allShipmentDetails.content_type_id,
          isIdRequired: false,
        }));
      }
    });

    // You can do any other action here, such as making an API request or updating the UI.
  }, [allShipmentDetails]);

  const [dataSampleIdWeight, setDataSampleIdWeight] = useState(
    allShipmentDetails?.ship_data_sample?.length > 0
      ? allShipmentDetails?.ship_data_sample?.map((data) => {
          return {
            id: data?.data_sample[0]?.id,
            sample_id: data.data_sample_id,
            weight: data.weight,
            usage: data?.data_sample[0]?.usage,
            name: data?.data_sample[0]?.name,
            isIdRequiredInSample: true,
            shipment_data_sample_id: data.ship_data_sample_id,
          };
        })
      : []
  );

  const [clickDropdownFlag, setClickDropdownFlag] = useState(false);
  const [searchedInputValue, setSearchedInputValue] = useState({
    id: "",
    name: "",
    usage: "",
  });
  const [dropdownFlag, setDropdownFlag] = useState(false);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      setClickDropdownFlag(false);
      const context = this;
      setSearchedInputValue({
        id: "",
        name: args[0],
      });
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const searchHandleChange = async (value) => {
    if (value.length > 3) {
      await ApiUtils.searchDataSample(value)
        .then((res) => {
          if (res.status === 200) {
            setSearchedSampleData(res.data.data);
            setDropdownFlag(true);
          }
        })
        .catch((err) => setSearchedSampleData([]));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchSample = useCallback(debounce(searchHandleChange), []);

  const addSamples = () => {
    const findSampleIndex = dataSampleIdWeight?.findIndex(
      (val) => Number(val?.sample_id) === Number(searchedInputValue.id)
    );

    if (findSampleIndex >= 0) {
      ToasterWarning("This sample is already added");
    } else {
      if (clickDropdownFlag) {
        setDataSampleIdWeight([
          ...dataSampleIdWeight,
          {
            id: "",
            name: searchedInputValue.name,
            weight: "",
            sample_id: searchedInputValue.id,
            isIdRequiredInSample: false,
            usage: searchedInputValue.usage,
          },
        ]);
        setSearchedInputValue({
          id: "",
          name: "",
          usage: "",
        });
        setClickDropdownFlag(false);
      }
    }
  };

  const handleContainerNumber = (e, type) => {
    // console.log(e);
    let indexOfId = containerNumberText.findIndex(
      (val) => Number(val.id) === Number(e.target.id)
    );
    if (indexOfId >= 0) {
      let updatedData = [...containerNumberText];
      updatedData[indexOfId].value = e.target.value;
      updatedData[indexOfId].type = type;
      // console.log(updatedData);
      setContainerNumberText(updatedData);
    } else {
      setContainerNumberText([
        ...containerNumberText,
        {
          id: e.target.id,
          value: e.target.value,
          type: type,
        },
      ]);
    }
  };

  const handleWeightInput = (e) => {
    const updatedDataSampleIdWeight = dataSampleIdWeight?.map((item) => {
      if (Number(item.sample_id) === Number(e.target.id)) {
        return {
          ...item,
          weight: e.target.value,
        };
      }
      return item;
    });

    setDataSampleIdWeight(updatedDataSampleIdWeight);
  };

  useEffect(() => {
    if (clickedNextTab) {
      goToStepFour();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedNextTab]);
  // useEffect(() => {
  //   if (shipmentData?.stepNumber >= 4 && !searchShipmentId) {
  //     setContainerNumberText([]);
  //     setDataSampleIdWeight([]);
  //   }
  // }, []);
  useEffect(() => {
    console.log("allshipment", allShipmentDetails);
    console.log(containerNumberText);
    // console.log(containerNumberText.filter((el) => el.type === 2));
  }, []);
  async function goToStepFour(e, error, values) {
    if (error?.length > 0 || dataSampleIdWeight.length === 0) {
      ToasterWarning("Please fill all the input field");
    }
    //   // ToasterWarning("Please fill all the input field");
    // }
    else if (error !== undefined) {
      e.preventDefault();
      const formData = new FormData();
      // console.log(containerNumberText);
      containerNumberText.forEach((data, index) => {
        // console.log(data);
        formData.append(`ship_containers[${index}][number]`, data.value);
        formData.append(
          `ship_containers[${index}][container_type_id]`,
          data.type
        );
        if (data.isIdRequired) {
          formData.append(`ship_containers[${index}][id]`, data.id);
        }
      });
      // console.log(formToJSON(formData));
      dataSampleIdWeight.forEach((data, index) => {
        formData.append(`data_sample[${index}][weight]`, data.weight);
        formData.append(
          `data_sample[${index}][data_sample_id]`,
          data.sample_id
        );
        if (data.isIdRequiredInSample) {
          formData.append(
            `data_sample[${index}][id]`,
            data.shipment_data_sample_id
          );
        }
      });

      formData.append("step", 3);
      formData.append("id", shipmentData.id);
      // formData.append("status", 1);
      await ApiUtils.createShipment(formData)
        .then((res) => {
          if (res.status === 200) {
            setActiveTabProgress(tabId + 1);
            setProgressValue(progressValue + 25);
            setClickedNextTab(false);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  }
  const onDeleteSampleIncluded = async (id, sampleId, shipmentID) => {
    const findSampleIndex = allShipmentDetails?.ship_data_sample.findIndex(
      (val) => Number(val?.data_sample_id) === Number(sampleId)
    );

    if (findSampleIndex >= 0) {
      await ApiUtils.deleteDataSampleInShipment(shipmentID)
        .then((res) => {
          if (res.status === 200) {
            ToasterSuccess(res.data.message);
            const data = dataSampleIdWeight.filter(
              (val) => Number(val.id) !== Number(id)
            );
            setDataSampleIdWeight(data);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    } else {
      const data = dataSampleIdWeight.filter(
        (val) => Number(val.sample_id) !== Number(sampleId)
      );
      setDataSampleIdWeight(data);
    }
  };
  return (
    <React.Fragment>
      <TabPane tabId={3}>
        <div>
          <AvForm
            id="step-3"
            onSubmit={(e, error, values) =>
              clickedNextTab && goToStepFour(e, error, values)
            }
          >
            <Row>
              <Col lg="6">
                <div
                  className="custom-card"
                  style={{ maxHeight: "250px", overflowY: "auto" }}
                >
                  <div className="card-header d-flex" style={{ gap: "13px" }}>
                    <Label className="form-label" htmlFor="upload-samples">
                      Container Included
                    </Label>
                    <span className="card-badge-container-num">
                      {shipmentIDNumber}
                    </span>
                  </div>
                  <div className="card-body">
                    {(allShipmentDetails.content_type_id === 3 ||
                      allShipmentDetails.content_type_id === 5) && (
                      <Row>
                        <Col lg={6}>
                          <Label
                            className="form-label"
                            htmlFor="total-amount-in-words"
                          >
                            Physcial Container ID
                          </Label>
                          {containerNumberText
                            .filter((data) => +data.type === 2)
                            .map((data, i) => {
                              return (
                                <Col lg={12} key={i}>
                                  <div className="mb-3">
                                    <AvField
                                      errorMessage="Please enter container number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      type="text"
                                      onKeyPress={(event) => {
                                        if (event.charCode < 48) {
                                          event.preventDefault();
                                        }
                                      }}
                                      className="form-control"
                                      placeholder="eg: 12345"
                                      id={data.id}
                                      name={data.id}
                                      onChange={(e) => {
                                        handleContainerNumber(e, data.type);
                                      }}
                                      value={data.value}
                                    />
                                  </div>
                                </Col>
                              );
                            })}
                        </Col>

                        <Col lg={6}>
                          <Label
                            className="form-label"
                            htmlFor="total-amount-in-words"
                          >
                            Chemical Container ID
                          </Label>
                          {containerNumberText
                            .filter((data) => +data.type === 1)
                            .map((data, i) => {
                              return (
                                <Col lg={12} key={i}>
                                  <div className="mb-3">
                                    <AvField
                                      errorMessage="Please enter container number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      type="text"
                                      onKeyPress={(event) => {
                                        if (event.charCode < 48) {
                                          event.preventDefault();
                                        }
                                      }}
                                      className="form-control"
                                      placeholder="eg: 12345"
                                      id={data.id}
                                      name={data.id}
                                      onChange={(e) => {
                                        handleContainerNumber(e, data.type);
                                      }}
                                      value={data.value}
                                    />
                                  </div>
                                </Col>
                              );
                            })}
                        </Col>
                      </Row>
                    )}
                    {(allShipmentDetails.content_type_id === 1 ||
                      allShipmentDetails.content_type_id === 2 ||
                      allShipmentDetails.content_type_id === 4) && (
                      <Row>
                        <Label
                          className="form-label"
                          htmlFor="total-amount-in-words"
                        >
                          Container ID
                        </Label>
                        {containerNumberText.map((data, i) => {
                          return (
                            <Col lg={6} key={i}>
                              <div className="mb-3">
                                <AvField
                                  errorMessage="Please enter container number"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  type="text"
                                  onKeyPress={(event) => {
                                    if (event.charCode < 48) {
                                      event.preventDefault();
                                    }
                                  }}
                                  className="form-control"
                                  placeholder="eg: 12345"
                                  id={data.id}
                                  name={data.id}
                                  onChange={(e) => {
                                    handleContainerNumber(e, data.type);
                                  }}
                                  value={data.value}
                                />
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="custom-card">
                  <div className="card-header d-flex justify-content-between">
                    <Label className="form-label" htmlFor="upload-samples">
                      Data Samples Included
                    </Label>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <FormGroup className="mb-3">
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Auto complete list of data sample in DB"
                            onChange={(e) => onSearchSample(e.target.value)}
                            value={searchedInputValue.name}
                          />

                          <div className="input-group-append">
                            <Button
                              type="button"
                              onClick={addSamples}
                              color="info"
                              disabled={
                                !clickDropdownFlag ||
                                dropdownFlag ||
                                (!dropdownFlag &&
                                  searchedInputValue.name === "")
                              }
                            >
                              Add to shipment
                            </Button>
                          </div>
                          {searchedInputValue.name.length < 4 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              At least four characters are required to fetch
                              data.
                            </div>
                          )}
                        </InputGroup>

                        {searchedSampleData?.length > 0 &&
                          searchedInputValue?.name !== "" && (
                            <div className="card">
                              <Dropdown
                                isOpen={dropdownFlag}
                                toggle={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <DropdownToggle
                                  color="info"
                                  caret
                                  style={{ display: "none" }}
                                >
                                  Dropdown button{" "}
                                  <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {searchedSampleData.map((data, i) => {
                                    return (
                                      <DropdownItem
                                        key={i}
                                        onClick={(e) => {
                                          setClickDropdownFlag(true);
                                          setDropdownFlag(false);
                                          setSearchedInputValue({
                                            id: data.id,
                                            name: data.name,
                                            usage: data.usage,
                                          });
                                          setSearchedSampleData([]);
                                        }}
                                      >
                                        {data.name}
                                      </DropdownItem>
                                    );
                                  })}
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          )}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col xs={12}>
                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                              style={{ maxHeight: "250px" }}
                            >
                              <Table
                                id="tech-companies-1"
                                striped
                                bordered
                                responsive
                              >
                                <thead>
                                  <tr>
                                    <th data-priority="1">
                                      Selected Data Sample
                                    </th>
                                    <th data-priority="2">Usage</th>
                                    <th data-priority="3">Weight</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dataSampleIdWeight?.length > 0 &&
                                    dataSampleIdWeight?.map((sample, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{sample?.name}</td>
                                          <td>{sample?.usage}</td>
                                          <td>
                                            <div
                                              className="mb-3"
                                              style={{
                                                display: "flex",
                                                gap: "9px",
                                              }}
                                            >
                                              <AvField
                                                errorMessage="Please enter weight"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                type="number"
                                                className="form-control"
                                                placeholder="eg: 50"
                                                id={sample?.sample_id}
                                                name={sample?.sample_id}
                                                onChange={handleWeightInput}
                                                min="0"
                                                onKeyPress={(event) => {
                                                  if (event.charCode < 48) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                value={sample?.weight}
                                              />
                                              <i
                                                className="ri-delete-bin-fill font-size-18"
                                                style={{ marginTop: "6px" }}
                                                onClick={() =>
                                                  onDeleteSampleIncluded(
                                                    sample?.id,
                                                    sample?.sample_id,
                                                    sample?.shipment_data_sample_id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </TabPane>
    </React.Fragment>
  );
}

export default Step3;
