import React, { useEffect, useState } from "react";
import { Collapse, Container } from "reactstrap";
import { Link } from "react-router-dom";
import classname from "classnames";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../config/constants";
import { useUserRole } from "../../helper/UserProvider";

const Navbar = (props) => {
  const [shipmentState, setShipmentState] = useState(false);
  const [invoiceState, setInvoiceState] = useState(false);
  const [usersState, setUsersState] = useState(false);
  const [clientState, setClientState] = useState(false);
  const [dataSampleState, setDataSampleState] = useState(false);
  const [reportsState, setReportsState] = useState(false);
  const [brokersState, setBrokersState] = useState(false);

  const { userRole } = useUserRole();
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN;

  useEffect(() => {
    let matchingMenuItem = null;
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
    window.addEventListener("resize", handleScreenSizeChange);
    return () => {
      window.removeEventListener("resize", handleScreenSizeChange);
    };
  }, []);
  const toggleShipmentDropdown = () => {
    setShipmentState(!shipmentState);
  };
  const toggleInvoceDropdown = () => {
    setInvoiceState(!invoiceState);
  };
  const toggleUsersDropdown = () => {
    setUsersState(!usersState);
  };
  const toggleClientsDropdown = () => {
    setClientState(!clientState);
  };
  const toggleDataSamplesDropdown = () => {
    setDataSampleState(!dataSampleState);
  };
  const toggleReportsDropdown = () => {
    setReportsState(!reportsState);
  };
  const toggleBrokersDropdown = () => {
    setBrokersState(!brokersState);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Define the "lg" breakpoint width (adjust as needed)
  const lgBreakpoint = 1024;

  // Function to handle the action when the screen width reaches "lg"
  const handleScreenSizeChange = () => {
    if (window.innerWidth >= lgBreakpoint) {
      // Perform your action when the screen width reaches "lg"
      // console.log('Screen width reached "lg".');
      setBrokersState(false);
      setClientState(false);
      setDataSampleState(false);
      setInvoiceState(false);
      setReportsState(false);
      setShipmentState(false);
      setUsersState(false);
    }
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="topnav">
        <Container fluid>
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.menuOpen}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="ri-dashboard-line me-2"></i> Dashboard
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-apps"
                    role="button"
                    onClick={toggleShipmentDropdown}
                  >
                    <i className="ri-ship-line me-2"></i>
                    Shipments
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu dropdown-menu-end", {
                      show: shipmentState,
                    })}
                    aria-labelledby="topnav-apps"
                  >
                    <Link to="/add-new-shipment" className="dropdown-item">
                      New Shipment
                    </Link>
                    <Link
                      to="/shipments-archive-list"
                      className="dropdown-item"
                    >
                      Manage Shipments
                    </Link>
                  </div>
                </li>
                {isUserTypeAdmin && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={toggleInvoceDropdown}
                    >
                      <i className="ri-coin-line me-2"></i>
                      Invoices
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu dropdown-menu-end", {
                        show: invoiceState,
                      })}
                      aria-labelledby="topnav-apps"
                    >
                      <Link to="/invoice-archive" className="dropdown-item">
                        View Invoices
                      </Link>
                      {/* <Link to="/payment-preview" className="dropdown-item">
                      View Payments
                    </Link> */}
                    </div>
                  </li>
                )}

                {isUserTypeAdmin && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      id="topnav-apps"
                      role="button"
                      onClick={toggleUsersDropdown}
                    >
                      <i className=" ri-user-3-line me-2"></i>
                      Users
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu dropdown-menu-end", {
                        show: usersState,
                      })}
                      aria-labelledby="topnav-apps"
                    >
                      <Link to="/users-list" className="dropdown-item">
                        List Users
                      </Link>
                    </div>
                  </li>
                )}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-apps"
                    role="button"
                    onClick={toggleBrokersDropdown}
                  >
                    <i className="ri-team-line me-2"></i>
                    Brokers
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu dropdown-menu-end", {
                      show: brokersState,
                    })}
                    aria-labelledby="topnav-apps"
                  >
                    <Link to="/brokers-list" className="dropdown-item">
                      List Brokers
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-apps"
                    role="button"
                    onClick={toggleClientsDropdown}
                  >
                    <i className="ri-building-4-line me-2"></i>
                    Company
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu dropdown-menu-end", {
                      show: clientState,
                    })}
                    aria-labelledby="topnav-apps"
                  >
                    <Link to="/company-list" className="dropdown-item">
                      List Company
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-apps"
                    role="button"
                    onClick={toggleDataSamplesDropdown}
                  >
                    <i className="ri-stack-line me-2"></i>
                    Data Samples
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu dropdown-menu-end", {
                      show: dataSampleState,
                    })}
                    aria-labelledby="topnav-apps"
                  >
                    <Link to="/data-sample-list" className="dropdown-item">
                      List Samples
                    </Link>
                  </div>
                </li>
                {isUserTypeAdmin && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      id="topnav-apps"
                      role="button"
                      onClick={toggleReportsDropdown}
                    >
                      <i className="ri-file-3-line me-2"></i>
                      Reports
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu dropdown-menu-end", {
                        show: reportsState,
                      })}
                      aria-labelledby="topnav-apps"
                    >
                      <Link to="/shipment-report" className="dropdown-item">
                        Shipments Report
                      </Link>
                      <Link to="/company-report" className="dropdown-item">
                        Company Report
                      </Link>
                      <Link to="/invoice-report" className="dropdown-item">
                        Invoice Report
                      </Link>
                      <Link
                        to="/shipments-less-than-fifty"
                        className="dropdown-item"
                      >
                        Annual Shipments Report
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
