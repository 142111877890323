import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import logo from "../assets/images/MEL-LOGO.png";

const DemoInvoice = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid justify-content-center align-items-center">
          {/* Center the card horizontally and vertically */}
          <Row>
            <Col className="col-12">
              <Card style={{ width: "50%", left: "27%" }}>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="d-flex gap-2">
                        <h5>Invoice No:</h5>
                        <p>INV12345</p>
                      </div>
                      <div className="d-flex gap-2">
                        <h5>Date:</h5>
                        <p>2023-08-08</p>
                      </div>
                      <div className="d-flex gap-2">
                        <h5>CDN:</h5>
                        <p>CDN67890</p>
                      </div>
                    </div>
                    <img src={logo} alt="Invoice" />
                  </div>

                  <hr className="mt-3" />

                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Invoice To:</h5>
                      Company name <br />
                      contact person name
                      <br />
                      number
                    </div>
                    <div dir="rtl">
                      <h5>:Paid To</h5>
                      Middle East Labs
                      <br />
                      ,Building 195, 1St Floor
                      <br />
                      West of Abu Fatira Al Herafia, Kuwait
                      <br />
                      Email : info@mideastlabs.com
                      <br />
                      Fax: +965 22251578
                    </div>
                  </div>
                  <div className="mt-3">
                    <h5>Broker:</h5>
                    XYZ Brokerage
                    <br />
                    number
                  </div>

                  <table class="table table-hover border mt-4">
                    <thead>
                      <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Product A</td>
                        <td>3</td>
                        <td>$20</td>
                        <td>$60</td>
                      </tr>
                      <tr>
                        <td>Product B</td>
                        <td>2</td>
                        <td>$25</td>
                        <td>$50</td>
                      </tr>

                      <>
                        <tr>
                          <td colSpan={2}>
                            <b>Total Amount in Words:</b> <br />
                            kjdfdkfj
                          </td>
                          <td colSpan={2}>
                            <b>
                              Total Amount: <span>$110</span>
                            </b>
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DemoInvoice;
