import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";


const NewHeader = () => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={logoSmLight} alt="" height="22" /> */}
                  <img
                    alt={"MEL"}
                    src="/MEL-logo.png"
                    style={{ width: "175px" }}
                  />
                </span>
                <span className="logo-lg">
                  {/* <img src={logoLight} alt="" height="20" /> */}
                  <img
                    alt={"MEL"}
                    src="/MEL-logo.png"
                    style={{ width: "175px" }}
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <footer className="footer" style={{ left: 0 }}>
        <Container fluid>
          <Row>
            <Col sm={12} className="text-center">
              {" "}
              © {new Date().getFullYear()} - MEL All rights reserved.
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default NewHeader;
