const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  invoices: "",
};

const invoiceSlice = createSlice({
  name: "invoiceData",
  initialState,
  reducers: {
    getInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    updateInvoiceStatus: (state, action) => {
      state.invoices = action.payload;
    },
    sendInvoiceToClient: (state, action) => {
      state.invoices = action.payload;
    },
  },
});

export const { getInvoices, updateInvoiceStatus } = invoiceSlice.actions;
export default invoiceSlice.reducer;
