export function convertAmountToWords(amount) {
  const singleDigits = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const tensPlace = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const teenNumbers = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const words = [];

  if (amount === 0) {
    return "zero";
  }

  // Function to convert a three-digit number
  function convertThreeDigitNumber(number) {
    const hundred = Math.floor(number / 100);
    const remainder = number % 100;
    const wordsArr = [];

    if (hundred > 0) {
      wordsArr.push(singleDigits[hundred]);
      wordsArr.push("hundred");
    }

    if (remainder > 0) {
      if (remainder < 10) {
        wordsArr.push(singleDigits[remainder]);
      } else if (remainder >= 10 && remainder < 20) {
        wordsArr.push(teenNumbers[remainder - 10]);
      } else {
        const tens = Math.floor(remainder / 10);
        const ones = remainder % 10;

        wordsArr.push(tensPlace[tens]);
        if (ones > 0) {
          wordsArr.push(singleDigits[ones]);
        }
      }
    }

    return wordsArr.join(" ");
  }

  // Split the amount into parts
  const crore = Math.floor(amount / 10000000);
  const lakh = Math.floor((amount % 10000000) / 100000);
  const thousand = Math.floor((amount % 100000) / 1000);
  const remaining = amount % 1000;

  if (crore > 0) {
    words.push(convertThreeDigitNumber(crore) + " crore");
  }

  if (lakh > 0) {
    words.push(convertThreeDigitNumber(lakh) + " lakh");
  }

  if (thousand > 0) {
    words.push(convertThreeDigitNumber(thousand) + " thousand");
  }

  if (remaining > 0) {
    words.push(convertThreeDigitNumber(remaining));
  }

  // Join all the parts and return the final result
  return words.join(" ");
}
