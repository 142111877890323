import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ApiUtils from "../../../api/ApiUtils";
import { useDispatch } from "react-redux";
import { setUserAuthDetails } from "../../../redux/slices/authSlice";
import { ToasterError } from "../../../helper/ToasterHelper";
import logo from "../../../assets/images/MEL-LOGO.png";
import { useUserRole } from "../../../helper/UserProvider";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setUserData } = useUserRole();
  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  const handleSubmit = async () => {
    const body = {
      email: email,
      password: password,
    };
    try {
      const res = await ApiUtils.authLogin(body);
      let formattedRes = {
        userToken: res.data.data.token,
      };
      if (res.status === 200) {
        localStorage.setItem("mel-user", JSON.stringify(formattedRes));
        dispatch(setUserAuthDetails(formattedRes));
        setUserData(res.data.data.admin);
        navigate("/dashboard", { state: { data: res.data.message } });
      }
    } catch (error) {
      console.log("🚀 ~ file: Login.js:42 ~ handleSubmit ~ error:", error)
      if (error && error.data && error.data.message) {
        ToasterError(error.data.message);
      } else if (error && error.data) {
        ToasterError(error.data);
      } else if (error) {
        ToasterError(error);
      }
    }
  };
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col lg={4}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <div>
                      <div className="text-center">
                        <div>
                          <Link to="/login">
                            <img
                              src={logo}
                              alt="MEL"
                              height="60"
                              className="auth-logo mx-auto"
                            />
                          </Link>
                        </div>
                      </div>

                      <div className="p-2 mt-5">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={() => handleSubmit()}
                        >
                          <div className="auth-form-group-custom mb-4">
                            <i className="ri-user-2-line auti-custom-input-icon text-info"></i>
                            <Label htmlFor="email">Email</Label>
                            <AvField
                              name="email"
                              value={email}
                              type="text"
                              className="form-control"
                              id="email"
                              errorMessage="Enter Email"
                              validate={{ email: true, required: true }}
                              placeholder="Enter email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="auth-form-group-custom mb-4">
                            <i className="ri-lock-2-line auti-custom-input-icon text-info"></i>
                            <Label htmlFor="userpassword">Password</Label>
                            <AvField
                              name="password"
                              value={password}
                              type="password"
                              className="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              errorMessage="Enter password"
                              validate={{ required: { value: true } }}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>

                          <div className="mt-4 text-center">
                            <Button
                              color="info"
                              className="w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </Button>
                          </div>
                        </AvForm>
                      </div>

                      <div className="mt-5 text-center">
                        <p>
                          © {new Date().getFullYear()} - MEL All rights
                          reserved.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Login;
