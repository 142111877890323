const { createSlice } = require("@reduxjs/toolkit");

const localStorageValue = JSON.parse(localStorage.getItem("mel-user"));

const initialState = {
  userDetails: localStorageValue,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserAuthDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    removeUserAuthDetails: (state) => {
      state.userDetails = "";
    },
  },
});

export const { setUserAuthDetails, removeUserAuthDetails } = authSlice.actions;
export default authSlice.reducer;
