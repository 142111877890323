const separateValues = (arrayOfObjects)=> {
    const label = [];
    const data = [];
  
    for (let i = 0; i < arrayOfObjects?.length; i++) {
      const object = arrayOfObjects[i];
  
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = object[key];
          if (typeof value === 'string') {
            label.push(value);
          } else if (typeof value === 'number') {
            data.push(value);
          }
        }
      }
    }
  
    return {
        label: label,
        data: data
    };
  }
export default separateValues  