const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  dataSample: "",
};

const dataSampleSlice = createSlice({
  name: "dataSampleList",
  initialState,
  reducers: {
    addDataSampleList: (state, action) => {
      state.dataSample = action.payload;
    },
    getDataSampleList: (state, action) => {
      state.dataSample = action.payload;
    },
    deleteDataSampleList: (state, action) => {
      state.dataSample = action.payload;
    },
    editDataSampleList: (state, action) => {
      state.dataSample = action.payload;
    },
    deleteDataSampleListComponent: (state, action) => {
      state.dataSample = action.payload;
    },
  },
});

export const { addDataSampleList } = dataSampleSlice.actions;
export default dataSampleSlice.reducer;
