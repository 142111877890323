const BASE_URL = process.env.REACT_APP_BASE_URL;
const USER_TYPE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE",
  SAMPLER: "SAMPLER",
};
module.exports = {
  BASE_URL,
  USER_TYPE,
};
