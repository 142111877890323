import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const MiniWidgets = ({ reports }) => {
  return (
    <>
      {reports.map((report, key) => (
        <Col key={key} md={3}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <p className="text-truncate font-size-14 mb-2">
                    {report.title}
                  </p>
                  <h4 className="mb-0">{report.value}</h4>
                </div>
                <div className="text-info">
                  <i className={report.icon + " font-size-40"}></i>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default MiniWidgets;
