import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import "../../../assets/custom/custom2.css";

import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../../api/ApiUtils";
import { useNavigate } from "react-router-dom";
import { ToasterError } from "../../../helper/ToasterHelper";
const ShipmentReport = () => {
  const [modalCenter, setModalCenter] = useState(false);
  const [shipmentListData, setShipmentListData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [filterType, setFilterType] = useState("");
  const [count, setCount] = useState();
  const [brokerList, setBrokerList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [dataSampleList, setDataSampleList] = useState([]);
  const [shipmentType, setShipmentType] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [formData, setFormData] = useState({
    customs_id: "",
    company: "",
    container_id: "",
    data_sample: "",
    port: "",
    shipment_type: "",
    bar_code: "",
    broker: "",
    end_date: "",
    start_date: "",
  });
  async function fetchShipmentList(type) {
    let url = `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${formData.customs_id}&company=${formData.company}&container_id=${formData.container_id}&data_sample=${formData.data_sample}&port=${formData.port}&filterShipmentType=${formData.shipment_type}&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${formData.end_date}`;
    if (type) {
      url = url + `&filterShipmentType=${type}`;
    }
    await ApiUtils.getShipmentFilter(url)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setTotalRecords(res ? res.data.data.shipmentCount : 0);
          setShipmentListData(res.data.data.shipmentList);
          setCount({
            shipmentCount: res.data.data.shipmentCount,
            shipmentLessThen50KgCount: res.data.data.shipmentLessThen50KgCount,
            shipmentUnderKepaCount: res.data.data.shipmentUnderKepaCount,
          });
        }
      })
      .catch((err) => setShipmentListData([]));
  }

  // async function fetchShipmentCount() {
  //   await ApiUtils.getShipmentCount(
  //     `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${formData.customs_id}&company=${formData.company}&container_id=${formData.container_id}&data_sample=${formData.data_sample}&port=${formData.port}&filterShipmentType=${formData.shipment_type}&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${formData.end_date}`
  //   )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setCount(res?.data?.data);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error && error.data && error.data.message) {
  //         ToasterError(error.data.message);
  //       } else if (error && error.data) {
  //         ToasterError(error.data);
  //       } else if (error) {
  //         ToasterError(error);
  //       }
  //     });
  // }
  async function fetchBrokerList() {
    await ApiUtils.getBrokersList()
      .then((res) => {
        setBrokerList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchPortList() {
    await ApiUtils.getPortList()
      .then((res) => {
        setPortList(res.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchDataSampleList() {
    await ApiUtils.getDataSample()
      .then((res) => {
        setDataSampleList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchContainerTypeList() {
    await ApiUtils.getContainerTypeList()
      .then((res) => {
        setShipmentType(res.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchCompanyList() {
    await ApiUtils.getContactsList()
      .then((res) => {
        setCompanyList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  useEffect(() => {
    setTimeout(() => {
      console.log(tableData);
    }, 1500);
  }, []);

  useEffect(() => {
    fetchShipmentList(filterType);
    //fetchShipmentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);
  const navigate = useNavigate();

  const onPreviewDataSample = (row) => {
    navigate(`/shipment-preview/${row?.id}`);
  };
  const sortShipmentFunc = async (field, order) => {
    await ApiUtils.getShipmentFilter(
      `sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}&filterShipmentType=${filterType}`
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setTotalRecords(res ? res.data.data.shipmentCount : 0);
          setShipmentListData(res.data.data.shipmentList);
        }
      })
      .catch((err) => setShipmentListData([]));
  };

  const tableData = shipmentListData.map((item) => {
    const staticData = {
      id: item.id,
      shipment_type: item.shipment_type,
      customs_decleration_number: item.customs_decleration_number,
      company_name: item.company_name,
      invoice_no: item.invoice_no,
      invoice_status:
        Number(item.invoice_status) === 1
          ? "Pending"
          : Number(item.invoice_status) === 2
          ? "Paid"
          : "Pending",
      shipment_status: item.status,
      deleted_at: item.deleted_at,
      shipment_date: item.ship_date
        ? new Date(item.ship_date)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
        : "",
      less_then_50kilos: item.less_then_50kg,
      ports: item.ports,
      kepa_status: item.kepa_status,
    };
    return staticData;
  });

  const togCenter = () => {
    fetchBrokerList();
    fetchPortList();
    fetchDataSampleList();
    fetchContainerTypeList();
    fetchCompanyList();
    setModalCenter(!modalCenter);
    setFormData({
      customs_id: "",
      company: "",
      container_id: "",
      data_sample: "",
      port: "",
      shipment_type: "",
      bar_code: "",
      broker: "",
      end_date: "",
      start_date: "",
    });
  };

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const columns = [
    {
      dataField: "id",
      text: "Shipment No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "shipment_type",
      text: "Shipment Type",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },
    {
      dataField: "customs_decleration_number",
      text: "Customs ID",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "invoice_no",
      text: "Invoice No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "invoice_status",
      text: "Invoice Status",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row?.invoice_status === "Paid" ? (
              <span className="badge bg-success font-size-12">Paid</span>
            ) : (
              <span className="badge bg-warning font-size-12">
                {row?.invoice_status}
              </span>
            )}
          </div>
        );
      },
    },
    // {
    //   dataField: "less_then_50kilos",
    //   text: "Less Then 50Kilos",
    //   // sort: true,
    //   // onSort: async (field, order) => {
    //   //   setSortColumn(field);
    //   //   setSortDirection(order);
    //   //   sortShipmentFunc(field, order);
    //   // },
    // },
    {
      dataField: "ports",
      text: "Ports",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "kepa_status",
      text: "KEPA Status",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },

    {
      dataField: "shipment_status",
      text: "Shipment Status",
      formatter: (cell, row) => {
        return (
          <div>
            {row?.deleted_at !== null ? (
              <span className="badge bg-danger font-size-12">Deleted</span>
            ) : (
              <span>{row?.shipment_status}</span>
            )}
          </div>
        );
      },
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },
    {
      dataField: "shipment_date",
      text: "Shipment Date",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },

    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      formatter: (_cellContent, row) => (
        <>
          <Button
            className="me-3 text-info tooltip-on-action btn1"
            onClick={() => onPreviewDataSample(row)}
          >
            <i className="ri ri-eye-line font-size-18"></i>
            <span className="tooltiptext">View</span>
          </Button>
        </>
      ),
    },
  ];

  const modalClose = () => {
    setModalCenter(false);
    setFormData({
      customs_id: "",
      company: "",
      container_id: "",
      data_sample: "",
      port: "",
      shipment_type: "",
      bar_code: "",
      broker: "",
      end_date: "",
      start_date: "",
    });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  async function fetchShipmentListFilter(fill) {
    setFilterType(fill);
    await ApiUtils.getShipmentFilter(
      `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${formData.customs_id}&company=${formData.company}&container_id=${formData.container_id}&data_sample=${formData.data_sample}&port=${formData.port}&filterShipmentType=${formData.shipment_type}&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${formData.end_date}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.shipmentCount : 0);
          setShipmentListData(res.data.data.shipmentList);
          setModalCenter(false);
          setCount({
            shipmentCount: res.data.data.shipmentCount,
            shipmentLessThen50KgCount: res.data.data.shipmentLessThen50KgCount,
            shipmentUnderKepaCount: res.data.data.shipmentUnderKepaCount,
          });
          //fetchShipmentCount();
        }
      })
      .catch((err) => setShipmentListData([]));
  }

  const onSubmitUser = () => {
    fetchShipmentListFilter();
  };

  const handleDownload = () => {
    if (
      formData.customs_id === "" &&
      formData.company === "" &&
      formData.container_id === "" &&
      formData.data_sample === "" &&
      formData.port === "" &&
      formData.shipment_type === "" &&
      formData.bar_code === "" &&
      formData.start_date === "" &&
      formData.end_date === ""
    ) {
      ApiUtils.getShipmentReport(
        `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${
          formData.customs_id
        }&company=${formData.company}&container_id=${
          formData.container_id
        }&data_sample=${formData.data_sample}&port=${
          formData.port
        }&filterShipmentType=${formData.shipment_type || filterType}&barcode=${
          formData.bar_code
        }&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${
          formData.end_date
        }`
      )
        .then((res) => {
          if (res.status === 200) {
            //window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    } else {
      ApiUtils.getShipmentReport(
        `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${
          count.shipmentCount
        }&page=${1}&customs_decleration_number=${formData.customs_id}&company=${
          formData.company
        }&container_id=${formData.container_id}&data_sample=${
          formData.data_sample
        }&port=${formData.port}&filterShipmentType=${
          formData.shipment_type || filterType
        }&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${
          formData.start_date
        }&to_date=${formData.end_date}`
      )
        .then((res) => {
          if (res.status === 200) {
            //window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const handleChange = (e, { name }) => {
    if (e?.label) {
      const selectedValue = e.value;
      setFormData({
        ...formData,
        [name]: selectedValue,
      });
    } else {
      setFormData({
        ...formData,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };

  const handleShipment = (event, type) => {
    event.preventDefault();
    fetchShipmentList(type);
    setFilterType(type);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Shipment Report</h4>

                <div className="page-title-right">
                  <div className="my-4 text-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light me-4"
                      onClick={togCenter}
                    >
                      <i className="ri-phone-find-fill font-size-15 mr-1" />
                      Find Shipment
                    </Button>
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light"
                      onClick={handleDownload}
                    >
                      <i className=" ri-download-2-fill font-size-15 me-1 mt-3" />
                      Download
                    </Button>
                  </div>

                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop={true}
                  >
                    <ModalHeader toggle={modalClose}>Find Shipment</ModalHeader>
                    <ModalBody>
                      <AvForm
                        onSubmit={(e, error, value) =>
                          onSubmitUser(e, error, value)
                        }
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="customs_id"
                                label="Customs Clarification ID"
                                type="text"
                                onChange={handleChange}
                                value={formData.customs_id}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Company</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="company"
                                options={companyList.map((text) => {
                                  return {
                                    label: text.company_name,
                                    value: text.company_name,
                                  };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.company}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="container_id"
                                label="Container ID"
                                type="text"
                                errorMessage="Please enter HSA code"
                                onChange={handleChange}
                                value={formData.container_id}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Data Sample</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="data_sample"
                                options={dataSampleList.map((text) => {
                                  return { label: text.name, value: text.name };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.data_sample}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Port</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="port"
                                options={portList.map((text) => {
                                  return { label: text.name, value: text.id };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.port}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Shipment Type
                              </Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="shipment_type"
                                options={shipmentType.map((text) => {
                                  return { label: text.name, value: text.id };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.shipment_type}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="bar_code"
                                label="Bar Code"
                                type="text"
                                errorMessage="Please enter usage"
                                onChange={handleChange}
                                value={formData.bar_code}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Broker</Label>
                              <Select
                                name="broker"
                                options={brokerList.map((text) => {
                                  return { label: text.name, value: text.name };
                                })}
                                onChange={handleChange}
                                defaultValue={formData?.broker}
                                classNamePrefix="select3-selection"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="shipment-date-input"
                                className="form-label"
                              >
                                Start Date
                              </Label>
                              <AvField
                                type="date"
                                id="shipment-date-input"
                                name="start_date"
                                onChange={handleChange}
                                value={formData?.start_date}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="shipment-date-input"
                                className="form-label"
                              >
                                End Date
                              </Label>
                              <AvField
                                type="date"
                                id="shipment-date-input"
                                name="end_date"
                                onChange={handleChange}
                                value={formData?.end_date}
                              />
                            </div>
                          </Col>
                        </Row>

                        <FormGroup className="mb-0" style={{ float: "right" }}>
                          <div>
                            <Button type="submit" color="info" className="me-1">
                              FIND SHIPMENTS
                            </Button>{" "}
                            <Button
                              type="reset"
                              color="secondary"
                              onClick={modalClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <a href="*" onClick={(e) => handleShipment(e, "")}>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          TOTAL SHIPMENTS
                        </p>
                        <h1 className="mb-0">{count?.shipmentCount}</h1>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
            <Col md={4}>
              <a href="*" onClick={(e) => handleShipment(e, "5")}>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          SHIPMENTS LESS THAN 50KG
                        </p>
                        <h1 className="mb-0">
                          {count?.shipmentLessThen50KgCount}
                        </h1>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
            <Col md={4}>
              <a href="*" onClick={(e) => handleShipment(e, "4")}>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          SHIPMENTS NOT UNDER KEPA
                        </p>
                        <h1 className="mb-0">
                          {count?.shipmentUnderKepaCount}
                        </h1>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2"></Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex mt-3">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShipmentReport;
