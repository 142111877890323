import React from "react";
import "./assets/scss/theme.scss";
import "./assets/custom/custom.css";
import AppRoute from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./helper/UserProvider";

function App() {
  return (
    <UserProvider>
      <React.Fragment>
        <AppRoute />
        <ToastContainer />
      </React.Fragment>
    </UserProvider>
  );
}

export default App;
