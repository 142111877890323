import React, { useEffect, useState } from "react";
import { Row, Col, TabPane, Label } from "reactstrap";
import Select from "react-select";
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import ApiUtils from "../../../api/ApiUtils";
import { ToasterError } from "../../../helper/ToasterHelper";
import { useDispatch, useSelector } from "react-redux";
import { setContainerNumber } from "../../../redux/slices/shipmentSlice";
import { USER_TYPE } from "../../../config/constants";
import { useUserRole } from "../../../helper/UserProvider";
import { ports, countries } from "./supportData";
import { toast } from "react-toastify";

function Step2({
  tabId,
  clickedNextTab,
  setActiveTabProgress,
  setClickedNextTab,
  setProgressValue,
  progressValue,
  allShipmentDetails,
  searchShipmentId,
  onChildEvent,
}) {
  const [contactList, setContactList] = useState([]);
  const [portList, setPortList] = useState(ports);
  const [brokerList, setBrokerList] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState(countries);
  let discount = Number(localStorage.getItem("discount"));
  let discount_type = localStorage.getItem("discount_type");

  const [fieldsData, setFieldsData] = useState({
    ship_date: allShipmentDetails?.ship_date
      ? allShipmentDetails?.ship_date
      : "",
    company_name_id: allShipmentDetails?.company_name_id
      ? allShipmentDetails?.company_name_id
      : "",
    broker_id: allShipmentDetails?.broker_id
      ? allShipmentDetails?.broker_id
      : "",
    port_id: allShipmentDetails?.port_id ? allShipmentDetails?.port_id : "",
    customs_decleration_number: allShipmentDetails?.customs_decleration_number
      ? allShipmentDetails?.customs_decleration_number
      : "",
    awb_number: allShipmentDetails?.awb_number
      ? allShipmentDetails?.awb_number
      : "",
    total_container: allShipmentDetails?.total_container
      ? Number(allShipmentDetails?.total_container)
      : "",
    exporting_import_country_id: allShipmentDetails?.exporting_import_country_id
      ? allShipmentDetails?.exporting_import_country_id
      : "",
    manufacturing_company_name: allShipmentDetails?.manufacturing_company_name
      ? allShipmentDetails?.manufacturing_company_name
      : "",
    chemical_container: allShipmentDetails?.chemical_container
      ? Number(allShipmentDetails?.chemical_container)
      : "",
    origin_country_id: allShipmentDetails?.origin_country_id
      ? allShipmentDetails?.origin_country_id
      : "",
    origin_certificate_number: allShipmentDetails?.origin_certificate_number
      ? allShipmentDetails?.origin_certificate_number
      : "",
    physical_container: allShipmentDetails?.physical_container
      ? Number(allShipmentDetails?.physical_container)
      : "",
    discount: discount,
    discount_type: discount_type,

    invoice_date: allShipmentDetails?.invoice_date
      ? allShipmentDetails?.invoice_date
      : "",
    invoice_number: allShipmentDetails?.invoice_number
      ? allShipmentDetails?.invoice_number
      : "",
  });
  const { userRole } = useUserRole();
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN ? true : false;

  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(localStorage.getItem("discount"));
    // console.log(localStorage.getItem("discount_type"));
    fetchContactList();
    // fetchPortList();
    fetchBrokerList();
    // fetchCountriesList();
    // if (shipmentData?.stepNumber >= 4 && !searchShipmentId) {
    //   setFieldsData({
    //     ship_date: "",
    //     company_name_id: "",
    //     broker_id: "",
    //     port_id: "",
    //     customs_decleration_number: "",
    //     awb_number: "",
    //     total_container: "",
    //     exporting_import_country_id: "",
    //     manufacturing_company_name: "",
    //     chemical_container: "",
    //     origin_country_id: "",
    //     origin_certificate_number: "",
    //     physical_container: "",
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchContactList() {
    await ApiUtils.getContactsList("type=shipment")
      .then((res) => {
        if (res.status === 200) {
          let contactData = res.data.data.map((val, id) => ({
            ...val,
            value: val.company_name,
            label:
              allShipmentDetails?.content_type_id === 5
                ? `${val.company_name} (${val.shipment_count})`
                : val.company_name,
          }));

          setContactList(contactData);
        }
      })
      .catch((err) => setContactList([]));
  }
  // async function fetchPortList() {
  //   await ApiUtils.getPortList()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let portData = res.data.data.map((val) => ({
  //           ...val,
  //           value: val.id,
  //           label: val.name,
  //         }));
  //         console.log(portData);
  //         setPortList(portData);
  //       }
  //     })
  //     .catch((err) => setPortList([]));
  // }
  async function fetchBrokerList() {
    await ApiUtils.getBrokerList("type=shipment")
      .then((res) => {
        if (res.status === 200) {
          let brokerData = res.data.data.map((val) => ({
            ...val,
            value: val.id,
            label: val.name,
          }));
          setBrokerList(brokerData);
        }
      })
      .catch((err) => setBrokerList([]));
  }
  // const fetchCountriesList = async () => {
  //   await ApiUtils.getCountriesList()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let countryData = res.data.data.map((c) => ({
  //           ...c,
  //           value: c.id,
  //           label: c.name,
  //         }));
  //         console.log(countryData);
  //         setCountriesOptions(countryData);
  //       }
  //     })
  //     .catch((err) => setCountriesOptions([]));
  // };

  const [validCDN, setValidCDN] = useState(false);
  const validateCDN = (e) => {
    const regex = /^[a-zA-Z]{3}\/.*\/[a-zA-Z]{3}\d{2}$/;
    const validated = regex.test(e.target.value);
    if (!validated) {
      toast("Invalid CDN format", { type: "error" });
      e.target.focus();
      setValidCDN(false);
    } else {
      setValidCDN(true);
    }
    return validated;
  };

  const handleChangeFields = (e) => {
    if (e?.label) {
      setFieldsData({
        ...fieldsData,
        [e.label]: e.id,
      });
    } else {
      setFieldsData({
        ...fieldsData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "discount") {
      localStorage.setItem("discount", e.target.value);
    }
  };
  const contactHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      company_name_id: e.id,
    });
  };
  const brokerHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      broker_id: e.id,
    });
  };
  const portHandleChange = (e) => {
    console.log(e);
    setFieldsData({
      ...fieldsData,
      port_id: e.id,
    });
    console.log(fieldsData);
  };

  const exportCountryHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      exporting_import_country_id: e.id,
    });
  };
  const originCountryHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      origin_country_id: e.id,
    });
  };
  const discountTypeHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      discount_type: e.label,
    });
    localStorage.setItem("discount_type", e.label);
  };

  useEffect(() => {
    if (clickedNextTab) {
      goToStepThree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedNextTab]);

  async function goToStepThree(e, error, values) {
    if (
      error?.length > 0 ||
      fieldsData.broker_id === "" ||
      fieldsData.company_name_id === "" ||
      fieldsData.port_id === "" ||
      fieldsData.origin_country_id === "" ||
      fieldsData.exporting_import_country_id === "" ||
      (fieldsData.discount !== "" &&
        fieldsData.discount !== "0" &&
        fieldsData.discount_type === "")
    ) {
      // ToasterWarning("Please fill all the input field");
    } else if (
      Number(fieldsData.total_container) <
        Number(fieldsData.chemical_container) +
          Number(fieldsData.physical_container) ||
      (Number(fieldsData.total_container) >
        Number(fieldsData.chemical_container) +
          Number(fieldsData.physical_container) &&
        allShipmentDetails.content_type_id !== 4)
    ) {
      ToasterError(
        "Sum of Physical & Chemical Container should not be greater or smaller than Total Container"
      );
    } else if (error !== undefined) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("awb_number", fieldsData.awb_number);
      formData.append("broker_id", fieldsData.broker_id);
      formData.append(
        "chemical_container",
        fieldsData.chemical_container ? fieldsData.chemical_container : 0
      );
      formData.append("company_name_id", fieldsData.company_name_id);
      formData.append(
        "customs_decleration_number",
        fieldsData.customs_decleration_number
      );
      formData.append(
        "exporting_import_country_id",
        fieldsData.exporting_import_country_id
      );
      formData.append(
        "manufacturing_company_name",
        fieldsData.manufacturing_company_name
      );
      formData.append(
        "origin_certificate_number",
        fieldsData.origin_certificate_number
      );
      formData.append("origin_country_id", fieldsData.origin_country_id);
      formData.append(
        "physical_container",
        fieldsData.physical_container ? fieldsData.physical_container : 0
      );
      formData.append("port_id", fieldsData.port_id);
      formData.append("ship_date", fieldsData.ship_date);
      formData.append("invoice_date", fieldsData.invoice_date);
      formData.append("invoice_number", fieldsData.invoice_number);
      formData.append("total_container", fieldsData.total_container);
      formData.append("step", 2);
      formData.append(
        "status",
        allShipmentDetails.status === null
          ? "Pending"
          : allShipmentDetails.status
      );
      formData.append("id", shipmentData?.id);
      formData.append("discount", fieldsData?.discount);
      formData.append("discount_type", fieldsData?.discount_type);
      await ApiUtils.createShipment(formData)
        .then((res) => {
          if (res.status === 200) {
            onChildEvent(formData);
            setClickedNextTab(false);
            dispatch(
              setContainerNumber(res.data.data.shipment.total_container)
            );
            setActiveTabProgress(tabId + 1);
            setProgressValue(progressValue + 25);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  }

  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <AvForm
          id="step-2"
          onSubmit={(e, error, values) =>
            clickedNextTab && goToStepThree(e, error, values)
          }
        >
          <Row className="mb-3">
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="shipment-date-input" className="form-label">
                  Shipment Date
                </Label>
                <AvField
                  errorMessage="Please select date"
                  validate={{ required: { value: true } }}
                  type="date"
                  id="shipment-date-input"
                  name="ship_date"
                  onChange={handleChangeFields}
                  value={fieldsData?.ship_date}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="shipment-contact-input" className="form-label">
                  Brokers
                </Label>
                <Select
                  onChange={brokerHandleChange}
                  options={brokerList}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  defaultValue={allShipmentDetails?.broker?.map((text, i) => {
                    return { label: text.name, value: text.id };
                  })}
                  value={
                    fieldsData?.broker_id !== ""
                      ? brokerList.find((el) => el.id === fieldsData.broker_id)
                      : ""
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.broker_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  validate={{ required: { value: true } }}
                  name="select"
                  required
                />
                {clickedNextTab && fieldsData.broker_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select broker name
                  </div>
                )}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="company_name">
                  Company Name
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={contactHandleChange}
                  options={contactList}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.company_name_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    allShipmentDetails?.client_contact?.length > 0
                      ? allShipmentDetails?.client_contact?.map((text, i) => {
                          return { label: text.company_name, value: text.id };
                        })
                      : ""
                  }
                  value={
                    fieldsData?.company_name_id !== ""
                      ? contactList.find(
                          (el) => el.id === fieldsData.company_name_id
                        )
                      : ""
                  }
                />
                {clickedNextTab && fieldsData.company_name_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select company name
                  </div>
                )}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="port_id" className="form-label">
                  Port Name
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={portHandleChange}
                  options={portList}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  name="port_id"
                  id="port_id"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.port_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    allShipmentDetails?.port?.length > 0
                      ? allShipmentDetails?.port?.map((text, i) => {
                          return { label: text.name, value: text.id };
                        })
                      : ""
                  }
                  value={
                    fieldsData?.port_id !== "0"
                      ? ports.find((el) => el.id === fieldsData.port_id)
                      : ""
                  }
                />
                {clickedNextTab && fieldsData.port_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select port name
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="customs-declaration-no">
                  Customs Declaration No.
                </Label>
                <AvField
                  errorMessage="Please enter a valid CDN [XXX/*/XXX11]"
                  validate={{
                    required: { value: true },
                    pattern: { value: "^[a-zA-Z]{3}/.*/[a-zA-Z]{3}\\d{2}$" },
                  }}
                  type="text"
                  className="form-control"
                  id="customs-declaration-no"
                  name="customs_decleration_number"
                  onChange={handleChangeFields}
                  onBlur={validateCDN}
                  value={fieldsData?.customs_decleration_number}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="awb-number">
                  AWB Number
                </Label>
                <AvField
                  errorMessage="Please enter AWB number"
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="awb-number"
                  name="awb_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.awb_number}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="number-of-containers">
                  Number of Containers
                </Label>
                <AvField
                  errorMessage="Please enter number of containers"
                  validate={{ required: { value: true } }}
                  type="number"
                  className="form-control"
                  placeholder="eg: 15"
                  id="number-of-containers"
                  name="total_container"
                  min="0"
                  onKeyPress={(event) => {
                    if (event.charCode < 48) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChangeFields}
                  value={fieldsData?.total_container}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="invoice-date-input" className="form-label">
                  Invoice Date
                </Label>
                <AvField
                  errorMessage="Please select date"
                  validate={{ required: { value: true } }}
                  type="date"
                  id="invoice-date-input"
                  name="invoice_date"
                  onChange={handleChangeFields}
                  value={fieldsData?.invoice_date}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label
                  className="form-label"
                  htmlFor="exporting_import_country_id"
                >
                  {shipmentData?.shipmentType === 1 ? "Import" : "Export"}{" "}
                  Country
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={exportCountryHandleChange}
                  options={countriesOptions}
                  classNamePrefix="select2-selection"
                  placeholder="Select from List"
                  name="exporting_import_country_id"
                  id="exporting_import_country_id"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab &&
                        fieldsData.exporting_import_country_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    allShipmentDetails?.importing_exporting_country?.length > 0
                      ? allShipmentDetails?.importing_exporting_country?.map(
                          (text, i) => {
                            return { label: text.name, value: text.id };
                          }
                        )
                      : ""
                  }
                  value={
                    fieldsData.exporting_import_country_id !== ""
                      ? countriesOptions.find(
                          (el) =>
                            el.id === fieldsData.exporting_import_country_id
                        )
                      : ""
                  }
                />
                {clickedNextTab &&
                  fieldsData.exporting_import_country_id === "" && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Please select any country
                    </div>
                  )}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label
                  className="form-label"
                  htmlFor="manufacturing_company_name"
                >
                  Manufacturing Company Name
                </Label>
                <AvField
                  errorMessage="Please enter manufacturing company name"
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="manufacturing_company_name"
                  name="manufacturing_company_name"
                  onChange={handleChangeFields}
                  value={fieldsData?.manufacturing_company_name}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="chemical-containers">
                  Chemical Containers
                </Label>
                <AvField
                  errorMessage="Please enter chemical containers"
                  validate={{ required: { value: true } }}
                  type="number"
                  className="form-control"
                  placeholder="eg: 10"
                  id="chemical-containers"
                  min="0"
                  onKeyPress={(event) => {
                    if (event.charCode < 48) {
                      event.preventDefault();
                    }
                  }}
                  name="chemical_container"
                  onChange={handleChangeFields}
                  // disabled={
                  //   allShipmentDetails.content_type_id !== 1 &&
                  //   allShipmentDetails.content_type_id !== 3 &&
                  //   allShipmentDetails.content_type_id !== 5
                  // }
                  value={fieldsData?.chemical_container || "0"}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="awb-number">
                  Invoice Number
                </Label>
                <AvField
                  errorMessage="Please enter invoice number"
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="invoice-number"
                  name="invoice_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.invoice_number}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="origin_country_id">
                  Origin Country
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={originCountryHandleChange}
                  options={countriesOptions}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  name="origin_country_id"
                  id="origin_country_id"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.origin_country_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    allShipmentDetails?.origin_country?.length > 0
                      ? allShipmentDetails?.origin_country?.map((text, i) => {
                          return { label: text.name, value: text.id };
                        })
                      : ""
                  }
                  value={
                    fieldsData.origin_country_id !== ""
                      ? countriesOptions.find(
                          (el) => el.id === fieldsData.origin_country_id
                        )
                      : ""
                  }
                />
                {clickedNextTab && fieldsData.origin_country_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select origin country
                  </div>
                )}
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label
                  className="form-label"
                  htmlFor="origin_certificate_number"
                >
                  Origin Certificate No.
                </Label>
                <AvField
                  errorMessage="Please enter origin certificate no."
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="origin_certificate_number"
                  name="origin_certificate_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.origin_certificate_number}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="physical-containers">
                  Physical Containers
                </Label>
                <AvField
                  errorMessage="Please enter physical containers"
                  validate={{ required: { value: true } }}
                  type="number"
                  className="form-control"
                  placeholder="eg: 10"
                  id="physical-containers"
                  name="physical_container"
                  min="0"
                  onKeyPress={(event) => {
                    if (event.charCode < 48) {
                      event.preventDefault();
                    }
                  }}
                  // disabled={
                  //   allShipmentDetails.content_type_id !== 2 &&
                  //   allShipmentDetails.content_type_id !== 3 &&
                  //   allShipmentDetails.content_type_id !== 5
                  // }
                  onChange={handleChangeFields}
                  value={fieldsData?.physical_container || "0"}
                />
              </div>
            </Col>
            {isUserTypeAdmin && (
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="discount">
                    Discount
                  </Label>

                  <AvField
                    errorMessage="Please enter Discount"
                    type="number"
                    className="form-control"
                    placeholder="eg: 10"
                    id="discount"
                    name="discount"
                    min="0"
                    onKeyPress={(event) => {
                      if (event.charCode < 48) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChangeFields}
                    value={fieldsData?.discount || "0"}
                  />
                </div>
              </Col>
            )}
            {isUserTypeAdmin && (
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="discount_type">
                    Discount Type
                  </Label>
                  <Select
                    // value={selectedGroup}
                    errorMessage="Please enter Discount Type"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          clickedNextTab &&
                          fieldsData.discount !== "" &&
                          fieldsData.discount_type === ""
                            ? "red"
                            : "hsl(0, 0%, 80%)",
                      }),
                    }}
                    onChange={discountTypeHandleChange}
                    validate={{
                      required: {
                        value:
                          fieldsData.discount !== "" ||
                          fieldsData.discount !== "0"
                            ? true
                            : false,
                      },
                    }}
                    options={[
                      { label: "Percentage", value: 2 },
                      { label: "Fixed", value: 1 },
                    ]}
                    classNamePrefix="select2-selection"
                    placeholder="Select from list"
                    name="discount_type"
                    id="discount_type"
                    defaultValue={
                      localStorage.getItem("discount") !== "none"
                        ? {
                            label: discount_type,
                            value: discount_type === "Fixed" ? 1 : 2,
                          }
                        : ""
                    }
                  />
                  {clickedNextTab &&
                    fieldsData.discount !== "" &&
                    fieldsData.discount_type === "" && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please select Discount Type
                      </div>
                    )}
                </div>
              </Col>
            )}
          </Row>
        </AvForm>
      </TabPane>
    </React.Fragment>
  );
}

export default Step2;
