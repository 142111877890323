import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import "../../../assets/custom/custom2.css";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import ApiUtils from "../../../api/ApiUtils";
import { ToasterError } from "../../../helper/ToasterHelper";
import { useNavigate, useParams } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

const CompanyPreview = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [shipmentListData, setShipmentListData] = useState([]);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortColumnInvoice, setSortColumnInvoice] = useState("id");
  const [sortDirectionInvoice, setSortDirectionInvoice] = useState("asc");
  const [contacts, setContacts] = useState([]);
  const [totalRecordsInvoice, setTotalRecordsInvoice] = useState(0);
  const [sizePerPageInvoice, setSizePerPageInvoice] = useState(10);
  const [currentPageInvoice, setCurrentPageInvoice] = useState(1);
  const [brokerList, setBrokerList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [dataSampleList, setDataSampleList] = useState([]);
  const [shipmentType, setShipmentType] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [count, setCount] = useState([]);
  const [modalCenter, setModalCenter] = useState(false);
  const [formData, setFormData] = useState({
    customs_id: "",
    container_id: "",
    data_sample: "",
    port: "",
    shipment_type: "",
    bar_code: "",
    broker: "",
    end_date: "",
    start_date: "",
  });
  const searchParam = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanyPreviewDetails();
    if (!searchParam?.id) {
      navigate("/company-report");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCoumpanyCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchBrokerList() {
    await ApiUtils.getBrokersList()
      .then((res) => {
        setBrokerList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchPortList() {
    await ApiUtils.getPortList()
      .then((res) => {
        setPortList(res.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchDataSampleList() {
    await ApiUtils.getDataSample()
      .then((res) => {
        setDataSampleList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchContainerTypeList() {
    await ApiUtils.getContainerTypeList()
      .then((res) => {
        setShipmentType(res.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  const togCenter = () => {
    fetchBrokerList();
    fetchPortList();
    fetchDataSampleList();
    fetchContainerTypeList();
    setModalCenter(!modalCenter);
    setFormData({
      customs_id: "",
      container_id: "",
      data_sample: "",
      port: "",
      shipment_type: "",
      bar_code: "",
      broker: "",
      end_date: "",
      start_date: "",
    });
  };
  const modalClose = () => {
    setModalCenter(false);
    setFormData({
      customs_id: "",
      container_id: "",
      data_sample: "",
      port: "",
      shipment_type: "",
      bar_code: "",
      broker: "",
      end_date: "",
      start_date: "",
    });
  };

  const handleChange = (e, { name }) => {
    if (e?.label) {
      const selectedValue = e.value;
      setFormData({
        ...formData,
        [name]: selectedValue,
      });
    } else {
      setFormData({
        ...formData,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };

  async function fetchShipmentListFilter() {
    await ApiUtils.getShipmentList(
      `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${formData.customs_id}&company=${searchParam?.id}&container_id=${formData.container_id}&data_sample=${formData.data_sample}&port=${formData.port}&filterShipmentType=${formData.shipment_type}&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${formData.end_date}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setShipmentListData(res?.data?.data?.data);
          setModalCenter(false);
        }
      })
      .catch((err) => setShipmentListData([]));
  }

  const onSubmitUser = () => {
    fetchShipmentListFilter();
  };

  async function fetchCoumpanyCount() {
    await ApiUtils.getShipmentCount(
      `type="company"&company_id=${searchParam?.id}&force_all=1`
    )
      .then((res) => {
        if (res.status === 200) {
          setCount(res.data.data);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  async function fetchCompanyPreviewDetails() {
    await ApiUtils.getCompanyByID(`${searchParam?.id}`)
      .then((res) => {
        if (res.status === 200) {
          setCompanyDetails(res.data.data);
        }
      })
      .catch((err) => navigate("/shipment-report"));
  }

  async function fetchShipmentList() {
    await ApiUtils.getShipmentList(
      `force_all=1&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${formData.customs_id}&company=${searchParam?.id}&container_id=${formData.container_id}&data_sample=${formData.data_sample}&port=${formData.port}&filterShipmentType=${formData.shipment_type}&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${formData.end_date}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setShipmentListData(res?.data?.data?.data);
        }
      })
      .catch((err) => setShipmentListData([]));
  }

  const fetchInvoiceList = async () => {
    await ApiUtils.getInvoices(
      `force_all=1&sort_column=${sortColumnInvoice}&sort_direction=${sortDirectionInvoice}&page_size=${sizePerPageInvoice}&page=${currentPageInvoice}&type=company&company_id=${searchParam?.id}`
    )
      .then((res) => {
        if (res?.status === 200) {
          setTotalRecordsInvoice(res ? res.data.data.total : 0);
          setContacts(res ? res.data.data.data : []);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
        setContacts([]);
      });
  };

  useEffect(() => {
    fetchShipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage, companyDetails?.company_name]);

  useEffect(() => {
    fetchInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageInvoice, sizePerPageInvoice]);

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const tableData = shipmentListData.map((item) => {
    const staticData = {
      id: item.id,
      shipment_type: item.shipment_type,
      customs_decleration_number: item.customs_decleration_number,
      company_name: item.company_name,
      invoice_no: item.invoice_no,
      invoice_status:
        Number(item.invoice_status) === 1
          ? "Pending"
          : Number(item.invoice_status) === 2
          ? "Paid"
          : "Pending",
      shipment_status: item.status,

      shipment_date: item.ship_date
        ? new Date(item.ship_date)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
        : "",
      less_then_50kilos: item.less_then_50kg,
      ports: item.ports,
      kepa_status: item.kepa_status,
    };
    return staticData;
  });

  const invoiceTableData = contacts?.map((item) => {
    const staticData = {
      id: item.id,
      issue_date: item.issue_date
        ? new Date(item.issue_date)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
        : "",
      customs_decleration_number: item?.shipment?.customs_decleration_number,
      company_name: item?.shipment?.client_contact[0]?.company_name,
      total_amount: item.total_amount,
      status: item.status === 1 ? "Pending" : "Paid",
    };
    return staticData;
  });

  const sortShipmentFunc = async (field, order) => {
    await ApiUtils.getShipmentList(
      `sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}&type=company&company=${searchParam?.id}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setShipmentListData(res?.data?.data?.data);
        }
      })
      .catch((err) => setShipmentListData([]));
  };

  const sortInvoiceFunc = async (field, order) => {
    await ApiUtils.getInvoices(
      `sort_column=${field}&sort_direction=${order}&page_size=${sizePerPageInvoice}&page=${currentPageInvoice}&type=company&company_id=${searchParam?.id}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecordsInvoice(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };

  const columns = [
    {
      dataField: "id",
      text: "Shipment No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "shipment_type",
      text: "Shipment Type",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },
    {
      dataField: "customs_decleration_number",
      text: "Customs ID",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "invoice_no",
      text: "Invoice No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "invoice_status",
      text: "Invoice Status",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row?.invoice_status === "Paid" ? (
              <span className="badge bg-success font-size-12">Paid</span>
            ) : (
              <span className="badge bg-warning font-size-12">
                {row?.invoice_status}
              </span>
            )}
          </div>
        );
      },
    },
    // {
    //   dataField: "less_then_50kilos",
    //   text: "Less Then 50Kilos",
    //   // sort: true,
    //   // onSort: async (field, order) => {
    //   //   setSortColumn(field);
    //   //   setSortDirection(order);
    //   //   sortShipmentFunc(field, order);
    //   // },
    // },
    {
      dataField: "ports",
      text: "Ports",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "kepa_status",
      text: "KEPA Status",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },

    {
      dataField: "shipment_status",
      text: "Shipment Status",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },
    {
      dataField: "shipment_date",
      text: "Shipment Date",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },
  ];

  const invoiceColumns = [
    {
      dataField: "id",
      text: "Invoice No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumnInvoice(field);
        setSortDirectionInvoice(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "issue_date",
      text: "Invoice Date",
      sort: true,
      onSort: async (field, order) => {
        setSortColumnInvoice(field);
        setSortDirectionInvoice(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "customs_decleration_number",
      text: "Customs Doc No.",
    },
    {
      dataField: "company_name",
      text: "Company Name",
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      sort: true,
      onSort: async (field, order) => {
        setSortColumnInvoice(field);
        setSortDirectionInvoice(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "status",
      text: "Invoice Status",
      sort: true,
      onSort: async (field, order) => {
        setSortColumnInvoice(field);
        setSortDirectionInvoice(order);
        sortInvoiceFunc(field, order);
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row?.status === "Paid" ? (
              <span className="badge bg-success font-size-12">Paid</span>
            ) : (
              <span className="badge bg-warning font-size-12">
                {row?.status}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const defaultSortedInvoice = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };
  const handleTableChangeInvoice = (_type, { page, sizePerPage }) => {
    setCurrentPageInvoice(page);
    setSizePerPageInvoice(sizePerPage);
  };

  return (
    <React.Fragment>
      <div className="page-content" id="invoice-preview">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Company Preview</h4>
                <div className="page-title-right">
                  <div className="my-4 text-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light"
                      onClick={togCenter}
                    >
                      <i className=" ri-filter-2-line font-size-15" />
                      Find Shipment
                    </Button>
                  </div>
                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop={true}
                  >
                    <ModalHeader toggle={modalClose}>Find Shipment</ModalHeader>
                    <ModalBody>
                      <AvForm
                        onSubmit={(e, error, value) =>
                          onSubmitUser(e, error, value)
                        }
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="customs_id"
                                label="Customs Clarification ID"
                                type="text"
                                onChange={handleChange}
                                value={formData.customs_id}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="container_id"
                                label="Container ID"
                                type="text"
                                errorMessage="Please enter HSA code"
                                onChange={handleChange}
                                value={formData.container_id}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Data Sample</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="data_sample"
                                options={dataSampleList.map((text) => {
                                  return { label: text.name, value: text.name };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.data_sample}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Port</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="port"
                                options={portList.map((text) => {
                                  return { label: text.name, value: text.id };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.port}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Shipment Type
                              </Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="shipment_type"
                                options={shipmentType.map((text) => {
                                  return { label: text.name, value: text.id };
                                })}
                                onChange={handleChange}
                                defaultValue={formData.shipment_type}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="bar_code"
                                label="Bar Code"
                                type="text"
                                errorMessage="Please enter usage"
                                onChange={handleChange}
                                value={formData.bar_code}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Broker</Label>
                              <Select
                                name="broker"
                                options={brokerList.map((text) => {
                                  return { label: text.name, value: text.name };
                                })}
                                onChange={handleChange}
                                defaultValue={formData?.broker}
                                classNamePrefix="select3-selection"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="shipment-date-input"
                                className="form-label"
                              >
                                Start Date
                              </Label>
                              <AvField
                                type="date"
                                id="shipment-date-input"
                                name="start_date"
                                onChange={handleChange}
                                value={formData?.start_date}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="shipment-date-input"
                                className="form-label"
                              >
                                End Date
                              </Label>
                              <AvField
                                type="date"
                                id="shipment-date-input"
                                name="end_date"
                                onChange={handleChange}
                                value={formData?.end_date}
                              />
                            </div>
                          </Col>
                        </Row>

                        <FormGroup className="mb-0" style={{ float: "right" }}>
                          <div>
                            <Button
                              type="submit"
                              color="info"
                              className="me-1"
                            >
                              FIND SHIPMENTS
                            </Button>{" "}
                            <Button
                              type="reset"
                              color="secondary"
                              onClick={modalClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          <Card style={{ paddingTop: "20px" }}>
            <CardBody>
              <div className="row justify-content-center">
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Company Name
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {companyDetails?.company_name}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Company Type
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {companyDetails?.company_type === 1
                          ? "Import"
                          : companyDetails?.company_type === 2
                          ? "Export"
                          : "Manufactured"}
                      </CardText>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Contact Name
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {companyDetails?.contact_name}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Contact Phone
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {companyDetails?.mobile_number}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Contact Email
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {companyDetails?.email}
                      </CardText>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {/* <a href="*" onClick={(e) => handleShipment(e, "5")}> */}
                    <Card body className="card-body-new-shipment">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              TOTAL SHIPMENTS
                            </p>
                            <h1 className="mb-0">{count.shipmentCount}</h1>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    {/* </a> */}
                  </Col>
                  <Col md={4}>
                    {/* <a href="*" onClick={(e) => handleShipment(e, "5")}> */}
                    <Card body className="card-body-new-shipment">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              SHIPMENTS LESS THAN 50KG
                            </p>
                            <h1 className="mb-0">
                              {count.shipmentLessThen50KgCount}
                            </h1>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    {/* </a> */}
                  </Col>
                  <Col md={4}>
                    {/* <a href="*" onClick={(e) => handleShipment(e, "4")}> */}
                    <Card body className="card-body-new-shipment">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              SHIPMENTS NOT UNDER KEPA
                            </p>
                            <h1 className="mb-0">
                              {count.shipmentUnderKepaCount}
                            </h1>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    {/* </a> */}
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title-desc">List of Shipments</h4>
                        <PaginationProvider
                          pagination={paginationFactory({
                            custom: true,
                            sizePerPage: sizePerPage,
                            totalSize: Number(totalRecords),
                            page: currentPage,
                          })}
                          keyField="id"
                          columns={columns}
                          data={tableData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={tableData}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2"></Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          remote
                                          keyField={"id"}
                                          responsive
                                          bordered={true}
                                          hover={true}
                                          pagination={paginationFactory}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap bg-white"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          onTableChange={handleTableChange}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex mt-3">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title-desc">List of Invoices</h4>
                        <PaginationProvider
                          pagination={paginationFactory({
                            custom: true,
                            sizePerPage: sizePerPageInvoice,
                            totalSize: Number(totalRecordsInvoice),
                            page: currentPageInvoice,
                          })}
                          keyField="id"
                          columns={invoiceColumns}
                          data={invoiceTableData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={invoiceColumns}
                              data={invoiceTableData}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2"></Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          remote
                                          keyField={"id"}
                                          responsive
                                          bordered={true}
                                          hover={true}
                                          pagination={paginationFactory}
                                          defaultSorted={defaultSortedInvoice}
                                          classes={
                                            "table align-middle table-nowrap bg-white"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          onTableChange={
                                            handleTableChangeInvoice
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex mt-3">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CompanyPreview;
