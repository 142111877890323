import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  DropdownToggle,
  DropdownMenu,
  Input,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../api/ApiUtils";

import { ToasterError, ToasterSuccess } from "../../helper/ToasterHelper";
import { useNavigate } from "react-router-dom";

function InvoiceArchive() {
  const [contacts, setContacts] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const fetchInvoiceList = async () => {
    await ApiUtils.getInvoices(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res?.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res ? res.data.data.data : []);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
        setContacts([]);
      });
  };
  useEffect(() => {
    fetchInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const tableData = contacts?.map((item) => {
    const staticData = {
      id: item.id,
      issue_date: item.issue_date
        ? new Date(item.issue_date)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
        : "",
      customs_decleration_number: item?.shipment?.customs_decleration_number,
      company_name: item?.shipment?.client_contact[0]?.company_name,
      total_amount: item.total_amount,
      status: item.status === 1 ? "Pending" : "Paid",
      shipment_id: item?.shipment_id,
      payed_at:
        item.payed_at === null ? "--" : moment(item.payed_at).calendar(),
      payment_method: item.payment_method === null ? "--" : item.payment_method,
      broker_name: item?.shipment?.broker[0]?.name,
    };
    console.log(staticData);
    return staticData;
  });

  const onPreviewInvoice = (data) => {
    navigate(`/invoice-preview/${data?.id}`);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getInvoices(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const sortInvoiceFunc = async (field, order) => {
    await ApiUtils.getInvoices(
      `search=${searchValue}&sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };

  const handleMarkAsPaid = (row) => {
    ApiUtils.updateInvoiceStatus({ id: row.id, status: 2 })
      .then((res) => {
        if (res.status === 200) {
          ToasterSuccess(res.data.message);
          fetchInvoiceList();
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const handleInvoiceSend = (row) => {
    const data = contacts?.find((d) => row.id === d.id);
    const invoiceData = {
      id: data.id,
    };
    ApiUtils.sendInvoiceToClient(invoiceData)
      .then((res) => {
        if (res.status === 200) {
          ToasterSuccess(res.data.message);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const onPreviewShipment = (row) => {
    navigate(`/shipment-preview/${row.shipment_id}`);
  };

  const columns = [
    {
      dataField: "id",
      text: "NO.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "issue_date",
      text: "Invoice Date",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "customs_decleration_number",
      text: "Customs Doc No.",
    },
    {
      dataField: "company_name",
      text: "Company Name",
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row?.status === "Paid" ? (
              <span className="badge bg-success font-size-12">Paid</span>
            ) : (
              <span className="badge bg-warning font-size-12">
                {row?.status}
              </span>
            )}
          </div>
        );
      },
    },
    {
      dataField: "shipment_id",
      text: "Shipment ID",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "broker_name",
      text: "Broker Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "payed_at",
      text: "Paid at",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "payment_method",
      text: "Method",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortInvoiceFunc(field, order);
      },
    },
    {
      dataField: "Action",
      text: "Action",
      isDummyField: true,
      formatter: (_cellContent, row) => {
        return (
          <>
            <UncontrolledDropdown className="text-center">
              <DropdownToggle tag="i" className="arrow-none">
                <i className="mdi mdi-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  className="action-dropdown-invoice"
                  onClick={() => handleMarkAsPaid(row)}
                  disabled={row.status === "Paid"}
                >
                  <i
                    className={
                      row.status === "Paid"
                        ? "ri-check-double-line"
                        : "ri ri-check-line"
                    }
                  ></i>
                  {row.status === "Paid" ? "Paid" : "Mark as Paid"}
                </DropdownItem>

                <DropdownItem
                  className="action-dropdown-invoice"
                  onClick={() => onPreviewInvoice(row)}
                >
                  <i className="ri ri-eye-line"></i>
                  View Invoice
                </DropdownItem>
                <DropdownItem
                  className="action-dropdown-invoice"
                  onClick={() => onPreviewShipment(row)}
                >
                  <i className="ri ri-eye-line"></i>
                  View Shipment
                </DropdownItem>

                <DropdownItem
                  className="action-dropdown-invoice"
                  onClick={() => handleInvoiceSend(row)}
                >
                  <i className="ri ri-send-plane-line"></i>
                  Send Invoice
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 my-4">Invoices Archive</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={4}>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Label
                                      htmlFor="shipment-date-input"
                                      className="form-label"
                                    >
                                      Search
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={(e) => onSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InvoiceArchive;
