import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import swal from "sweetalert";

import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../api/ApiUtils";

import {
  ToasterError,
  ToasterSuccess,
  ToasterWarning,
} from "../../helper/ToasterHelper";
import { useNavigate } from "react-router-dom";
function ContactsList() {
  const [modalCenter, setModalCenter] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [editContactId, setEditContactId] = useState("");
  const [sortColumn, setSortColumn] = useState("company_name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [contactDetails, setContactDetails] = useState({
    company_name: "",
    company_address: "",
    contact_name: "",
    country_id: "",
    email: "",
    mobile_number: "",
    phone_number: "",
    company_type: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const togCenter = () => {
    setModalCenter(!modalCenter);
    removeBodyCss();
  };

  const fetchContactsList = async () => {
    await ApiUtils.getContactsList(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res?.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res ? res.data.data.data : []);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
        setContacts([]);
      });
  };
  useEffect(() => {
    fetchContactsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const tableData = contacts?.map((item) => {
    const staticData = {
      company_name: item.company_name,
      company_type:
        item.company_type === 1
          ? "Import"
          : item.company_type === 2
          ? "Export"
          : item.company_type === 3
          ? "Manufactured"
          : "",
      contact_name: item.contact_name,
      phone_number: item.phone_number,
      email: item.email,
      id: item.id,
      country_id: item.country_id,
      mobile_number: item.mobile_number,
    };
    return staticData;
  });

  useEffect(() => {
    const fetchCountriesList = async () => {
      await ApiUtils.getCountriesList()
        .then((res) => {
          if (res.status === 200) {
            let countryData = res.data.data.map((c) => ({
              ...c,
              value: c.id,
              label: c.name,
            }));
            setCountriesOptions(countryData);
          }
        })
        .catch((err) => setContacts([]));
    };
    fetchCountriesList();
  }, []);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const optionGroup = [
    {
      label: "Countries List",
      options: countriesOptions,
    },
  ];

  const companyType = [
    { value: 1, label: "Import" },
    { value: 2, label: "Export" },
    { value: 3, label: "Manufactured" },
  ];

  const editContact = async (row) => {
    const data = contacts?.find((d) => row.id === d.id);
    removeBodyCss();
    let updatedUserData = {
      company_name: data?.company_name,
      company_address: data?.company_address,
      contact_name: data?.contact_name,
      country_id: data?.country_id,
      email: data?.email,
      mobile_number: data?.mobile_number,
      phone_number: data?.phone_number,
      company_type: data?.company_type,
    };
    setEditContactId(data.id);
    await setContactDetails(updatedUserData);
    await setIsEditMode(true);
    await setModalCenter(!modalCenter);
  };
  const deleteContact = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete company?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApiUtils.deleteClientContact(data.id)
          .then((res) => {
            if (res.status === 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setCurrentPage(1);
              fetchContactsList();
            }
          })
          .catch((error) => {
            if (error && error.data && error.data.message) {
              ToasterError(error.data.message);
            } else if (error && error.data) {
              ToasterError(error.data);
            } else if (error) {
              ToasterError(error);
            }
          });
      } else {
        swal({
          title: "Company not deleted!",
          icon: "error",
        });
      }
    });
  };

  const viewSummary = (row) => {
    navigate(`/company-preview/${row.id}`);
  };

  const columns = [
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },
    {
      dataField: "company_type",
      text: "Company Type",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },

    {
      dataField: "contact_name",
      text: "Contact Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },

    {
      dataField: "phone_number",
      text: "Contact Phone",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },
    {
      dataField: "email",
      text: "Contact Email",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortContactsFunc(field, order);
      },
    },
    // {
    //   dataField: "",
    //   text: "Shipment Under 50Kg",
    //   sort: true,
    //   onSort: async (field, order) => {
    //     setSortColumn(field);
    //     setSortDirection(order);
    //     sortContactsFunc(field, order);
    //   },
    // },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      formatter: (_cellContent, row) => (
        <>
          <UncontrolledDropdown className="text-center">
            <DropdownToggle tag="i" className="arrow-none">
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => viewSummary(row)}
              >
                <i className=" ri-file-list-3-fill font-size-18"></i>
                Summary
              </DropdownItem>
              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => editContact(row)}
              >
                <i className="mdi mdi-pencil font-size-18"></i>
                Update
              </DropdownItem>

              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => deleteContact(row)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  const handleUserInput = (e, { name }) => {
    if (e?.label) {
      const selectedValue = e.value;
      setContactDetails({
        ...contactDetails,
        [name]: selectedValue,
      });
    } else {
      setContactDetails({
        ...contactDetails,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };
  const modalClose = () => {
    setModalCenter(false);
    setIsEditMode(false);
    setEditContactId("");
    setContactDetails({
      company_name: "",
      company_address: "",
      contact_name: "",
      country_id: "",
      email: "",
      mobile_number: "",
      phone_number: "",
      company_type: "",
    });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const onSubmitUser = async (_e, error, _values) => {
    if (contactDetails.country_id === "" || error?.length > 0) {
      ToasterWarning("Please fill all the input field");
    } else {
      const body = {
        id: editContactId,
        company_name: contactDetails.company_name,
        company_address: contactDetails.company_address,
        contact_name: contactDetails.contact_name,
        country_id: contactDetails.country_id,
        email: contactDetails.email,
        mobile_number: contactDetails.mobile_number,
        phone_number: contactDetails.phone_number,
        company_type: contactDetails.company_type,
      };

      performOperation(isEditMode, isEditMode ? body : contactDetails)
        .then((res) => {
          if (res.status === 200) {
            fetchContactsList();
            setModalCenter(false);
            if (!isEditMode) {
              setTimeout(() => {
                setContactDetails({
                  company_name: "",
                  company_address: "",
                  contact_name: "",
                  country_id: "",
                  email: "",
                  mobile_number: "",
                  phone_number: "",
                  company_type: "",
                });
              }, 100);
            }
            setEditContactId("");
            setIsEditMode(false);
            ToasterSuccess(res.data.message);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const performOperation = (checkMode, params) =>
    !checkMode
      ? ApiUtils.createClientContact(params)
      : ApiUtils.updateClientContact(params);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getContactsList(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const sortContactsFunc = async (field, order) => {
    await ApiUtils.getContactsList(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setContacts(res.data.data.data);
        }
      })
      .catch((err) => setContacts([]));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Company List</h4>

                <div className="page-title-right">
                  <div className="my-4 text-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light"
                      onClick={togCenter}
                    >
                      Add Company
                    </Button>
                  </div>

                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop="static"
                  >
                    <ModalHeader toggle={modalClose}>
                      {isEditMode ? "Edit Company" : "Add Company"}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm onSubmit={onSubmitUser}>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="company_name"
                                label="Company Name"
                                placeholder="Enter company name"
                                type="text"
                                errorMessage="Please enter company name"
                                validate={{ required: { value: true } }}
                                onChange={handleUserInput}
                                value={contactDetails?.company_name}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">Company Type</Label>
                            <div className="mb-3">
                              <Select
                                value={companyType.filter((c) => {
                                  return (
                                    c.value === contactDetails.company_type
                                  );
                                })}
                                onChange={handleUserInput}
                                options={companyType}
                                classNamePrefix="select2-selection"
                                name="company_type"
                                placeholder="Select from list"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="company_address"
                                label="Company Address"
                                placeholder="Enter company address"
                                type="text"
                                errorMessage="Please enter company address"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.company_address}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Country</Label>

                              <Select
                                value={countriesOptions.filter(
                                  (c) =>
                                    parseInt(c?.id) ===
                                    parseInt(contactDetails?.country_id)
                                )}
                                onChange={handleUserInput}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                name="country_id"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="contact_name"
                                label="Contact Name"
                                placeholder="Enter contact name"
                                type="text"
                                errorMessage="Please enter contact name"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.contact_name}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="mobile_number"
                                label="Mobile Number"
                                placeholder="Enter mobile number"
                                type="number"
                                errorMessage={{
                                  required: "Please enter mobile number",

                                }}
                                validate={{
                                  required: { value: true },

                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.mobile_number}

                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email Address"
                                placeholder="Enter email address"
                                type="email"
                                errorMessage="Please enter email address"
                                validate={{
                                  required: { value: true },
                                  email: { value: true },
                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.email}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="phone_number"
                                label="Phone Number"
                                placeholder="Enter phone number"
                                type="number"
                                errorMessage={{
                                  required: "Please enter mobile number",

                                }}
                                validate={{
                                  required: { value: true },

                                }}
                                onChange={handleUserInput}
                                value={contactDetails?.phone_number}

                              />
                            </div>
                          </Col>
                        </Row>

                        <FormGroup className="mb-0" style={{ float: "right" }}>
                          <div>
                            <Button
                              type="submit"
                              color="info"
                              className="me-1"
                            >
                              {isEditMode ? "Update" : "Save"}
                            </Button>{" "}
                            <Button
                              type="reset"
                              color="secondary"
                              onClick={modalClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={4}>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Label
                                      htmlFor="shipment-date-input"
                                      className="form-label"
                                    >
                                      Search
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={(e) => onSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactsList;
