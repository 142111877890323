import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import {
  userProtectedRoutes,
  publicRoutes,
  adminProtectedRoutes,
  commonProtectedRoutes,
  unAuthRoutes,
} from "./index";
import HorizontalLayout from "../components/HorizontalLayout";
import UnauthLayout from "../components/UnauthLayout";
import Error404 from "../pages/Error/Error404";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPE } from "../config/constants";
import { setShipmentId } from "../redux/slices/shipmentSlice";
import { useUserRole } from "../helper/UserProvider";
function AppRoute() {
  const { userRole } = useUserRole();
  const userData = useSelector((state) => state.authSlice);
  const location = useLocation().pathname;
  const isAuthProtected = userData?.userDetails?.userToken ? true : false;
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN ? true : false;
  const dispatch = useDispatch();
  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const isUserTypeSampler =
    userRole.role_id === USER_TYPE.SAMPLER ? true : false;
  useEffect(() => {
    if (isAuthProtected) {
      if (document.body)
        document.body.setAttribute("data-layout", "horizontal");
    } else {
      if (document.body)
        document.body.removeAttribute("data-layout", "horizontal");
    }
  }, [isAuthProtected]);

  useEffect(() => {
    if (shipmentData?.id && location !== "/add-new-shipment") {
      dispatch(setShipmentId(""));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            !isAuthProtected ? (
              <Navigate to="/login" replace />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          }
        />
        {publicRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                isAuthProtected ? (
                  <Navigate
                    to="/dashboard"
                    replace
                    state={{ from: route.path }}
                  />
                ) : (
                  <route.component />
                )
              }
            />
          );
        })}

        {unAuthRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                !isAuthProtected ? (
                  <UnauthLayout>
                    <route.component />
                  </UnauthLayout>
                ) : (
                  <HorizontalLayout userType={isUserTypeAdmin}>
                    <route.component />
                  </HorizontalLayout>
                )
              }
            />
          );
        })}

        {userProtectedRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                !isAuthProtected ? (
                  <Navigate to="/login" replace state={{ from: route.path }} />
                ) : (
                  userRole.role_id !== undefined && (
                    <HorizontalLayout userType={isUserTypeAdmin}>
                      <route.component />
                    </HorizontalLayout>
                  )
                )
              }
            />
          );
        })}
        {adminProtectedRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                !isAuthProtected ? (
                  <Navigate to="/login" replace state={{ from: route.path }} />
                ) : !isUserTypeAdmin ? (
                  <Navigate
                    to="/dashboard"
                    replace
                    state={{ from: route.path }}
                  />
                ) : (
                  userRole.role_id !== undefined && (
                    <HorizontalLayout userType={isUserTypeAdmin}>
                      <route.component />
                    </HorizontalLayout>
                  )
                )
              }
            />
          );
        })}
        {commonProtectedRoutes.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                !isAuthProtected ? (
                  <Navigate to="/login" replace state={{ from: route.path }} />
                ) : isUserTypeSampler &&
                  route.path === "/pull-shipment-samples/:id" ? (
                  userRole.role_id !== undefined && (
                    <HorizontalLayout userType={isUserTypeAdmin}>
                      <route.component />
                    </HorizontalLayout>
                  )
                ) : isUserTypeSampler ? (
                  <Navigate
                    to="/dashboard"
                    replace
                    state={{ from: route.path }}
                  />
                ) : (
                  userRole.role_id !== undefined && (
                    <HorizontalLayout userType={isUserTypeAdmin}>
                      <route.component />
                    </HorizontalLayout>
                  )
                )
              }
            />
          );
        })}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </React.Fragment>
  );
}

export default AppRoute;
