import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Card, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import ApiUtils from "../../../api/ApiUtils";

const LessThanFiftyReport = (props) => {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("Shipment Less than 50 for client in 2024");
    const [loading, setLoading] = useState(true);

    const columns = [
        {
        dataField: "company_name",
        text: "Company Name",
        sort: true,
        },
        {
        dataField: "CDN",
        text: "Shipments CDNs",
        sort: true,
        },
        {
        dataField: "count",
        text: "Total Shipments",
        sort: true,
        },
    ];

    async function fetchData() {
        const result = await ApiUtils.getShipmentsLessThanFifty()
            .then((response) => {
                setData(response.data.data);
                setTitle(response.data.message);
                setLoading(false);
            });
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box d-flex align-items-center justify-content-between my-3">
                        <h4 className="mb-0">{title}</h4>
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <div className="table-responsive">
                                    <div className="card-body">
                                        <BootstrapTable
                                            keyField="id"
                                            data={data}
                                            columns={columns}
                                            bootstrap4
                                            bordered={true}
                                            hover={true}
                                            classes={"table align-middle table-nowrap bg-white"}
                                            headerWrapperClasses={"thead-light"}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

export default LessThanFiftyReport;
