import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Container,
  CardTitle,
  Button,
  CardText,
  CardBody,
} from "reactstrap";

import ApiUtils from "../../../api/ApiUtils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { convertAmountToWords } from "../../../helper/NumberToWords";
import { ToasterSuccess } from "../../../helper/ToasterHelper";
import { useUserRole } from "../../../helper/UserProvider";
import { USER_TYPE } from "../../../config/constants";

import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/custom/custom.css";

function ShipmentPreview() {
  const [shipmentDetails, setShipmentDetails] = useState({ invoice: [] });
  const searchParam = useParams();
  const { userRole } = useUserRole();
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN;
  const navigate = useNavigate();
  useEffect(() => {
    fetchShipmentPreviewDetails();
    if (!searchParam?.id) {
      navigate("/shipment-report");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let componentData = [];
  useEffect(() => {
    if (shipmentDetails?.ship_data_sample?.length > 0) {
      shipmentDetails?.ship_data_sample?.forEach((val) =>
        val?.data_sample?.forEach((data) =>
          data?.data_companent?.forEach((c) => {
            componentData.push({
              name: c.name,
              cas_code: c.cas_code,
              percentage: c?.percentage ? c?.percentage : "-",
            });
          })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentDetails]);

  async function fetchShipmentPreviewDetails() {
    await ApiUtils.getShipmentById(`id=${searchParam?.id}`)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res.data);
          setShipmentDetails(res.data.data);
          //console.log(shipmentDetails);
        }
      })
      .catch((err) => navigate("/shipment-report"));
  }
  const markReviewed = () => {
    // console.log(shipmentData, shipmentDetails);
    ApiUtils.markShipmenReviewed(shipmentDetails.id).then(() => {
      ToasterSuccess(
        `shipment ${shipmentDetails.id} Has Been Marked As Reviewed Successfully`
      );
      fetchShipmentPreviewDetails();
    });
  };

  const tableData = shipmentDetails?.ship_container?.map((d) => {
    const dummyData = {
      container: d?.number,
      type:
        d?.container_type_id === 1
          ? "Chemical"
          : d?.container_type_id === 2
          ? "Physical"
          : d?.container_type_id === 3
          ? "Chemical+Physical"
          : d?.container_type_id === 4
          ? "Not under KEPA"
          : d?.container_type_id === 5
          ? "Less then 50Kg"
          : "",
      barcode: d?.sample_barcode ? d?.sample_barcode : "-",
      datasample: d?.data_sample_name,
      HSA: d?.hsa_code,
      Uploads: d.ship_container_files_count,
      KEPA: d?.epa_status === 1 || !d?.epa_status ? "Pending" : "Sent",
      lab_result: d?.lab_report[0]?.status === 1 ? "Approved" : "Not Approved",
    };
    return dummyData;
  });

  // Column definitions
  const columns = [
    {
      dataField: "container",
      text: "Container",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "barcode",
      text: "Barcode",
    },
    {
      dataField: "datasample",
      text: "Data Sample",
    },
    {
      dataField: "HSA",
      text: "HSA",
    },
    {
      dataField: "Uploads",
      text: "Uploads",
    },
    {
      dataField: "KEPA",
      text: "KEPA",
    },
    {
      dataField: "lab_result",
      text: "Lab Result",
      formatter: (cell) => {
        return (
          <div
            className={`badge ${
              cell === "Approved"
                ? "badge-soft-success font-size-12"
                : cell === "Pending"
                ? "badge-soft-warning font-size-12"
                : cell === "Not Approved"
                ? "badge-soft-danger font-size-12"
                : ""
            } `}
          >
            {cell}
          </div>
        );
      },
    },
  ];

  // const sampleComponentsData = [
  //   {
  //     name: "Component 1",
  //     cas_number: "123-45-6",
  //     percentage: "20%",
  //   },
  // ];

  const sampleComponentsDataList = shipmentDetails?.ship_data_sample?.map(
    (data) => {
      const datasampleTableData = {
        data_sample: data?.name,
        weight: data?.weight,
        type:
          data?.type === 1
            ? "Chemical"
            : data?.type === 2
            ? "Physical"
            : data?.type === 3
            ? "Chemical and Physical"
            : data?.type === 4
            ? "Not Under KEPA"
            : data?.type === 5
            ? "Less than 50kg"
            : "",
        smds_file: data.smds_url,
        components: data?.data_sample?.[0]?.data_companent,
      };
      return datasampleTableData;
    }
  );

  const datasampleColumn = [
    {
      dataField: "data_sample",
      text: "Data Sample",
    },
    {
      dataField: "weight",
      text: "Weight",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "smds_file",
      text: "MSDS File",
      formatter: (_cellContent, row) => {
        return (
          <div>
            <Link to={row.smds_file} download target="_blank">
              <i className="ri-download-2-fill font-size-20 download-btn-inner" />
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "components",
      text: "Components",
      formatter: (_cellContent, row) => {
        return (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">CAS Number</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {row.components.map((component, index) => (
                  <tr key={index}>
                    <td className="tableRow">{component.name}</td>
                    <td className="tableRow">{component.cas_code}</td>
                    <td className="tableRow">{component.percentage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      },
    },
  ];

  const invoiceTotalAmountInWords = convertAmountToWords(
    shipmentDetails?.tota_amount
  );

  return (
    <React.Fragment>
      <div className="page-content" id="invoice-preview">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Shipment Preview</h4>
              </div>
            </Col>
          </Row>
          <Card style={{ paddingTop: "20px" }}>
            <CardBody>
              <div className="row justify-content-center">
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Date
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.ship_date}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Customs Declaration No.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.customs_decleration_number}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Current Step
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        <i
                          title={"Bayan Sent"}
                          className={`ri-mail-check-fill mx-2 text-${
                            shipmentDetails.send_bayan === 1
                              ? "success"
                              : "light"
                          }`}
                        ></i>
                        <i
                          title={"Samples Sent"}
                          className={`ri-barcode-line mx-2 text-${
                            shipmentDetails.pull_sample === 1
                              ? "success"
                              : "light"
                          }`}
                        ></i>
                        <i
                          title={"Lab Result Sent"}
                          className={`ri-flask-fill mx-2 text-${
                            shipmentDetails.lab_result === 1
                              ? "success"
                              : "light"
                          }`}
                        ></i>
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Type
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.ship_type === 1
                          ? "Import"
                          : shipmentDetails?.ship_type === 2
                          ? "Export"
                          : ""}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Content Type
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.container_type?.map((text, i) => {
                          return (
                            <span
                              key={i}
                              style={{ textTransform: "capitalize" }}
                            >
                              {text?.name}
                            </span>
                          );
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        AWB Number
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.awb_number}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={6}>
                    <Card body className="card-body-new-shipment">
                      <div className="d-flex align-items-center">
                        <div className="left-content">
                          <CardTitle className="h4 card-title-new-shipment strait-line">
                            {shipmentDetails?.ship_type === 1
                              ? "Import"
                              : "Export"}{" "}
                            Company
                          </CardTitle>
                          <CardText className="card-text-new-shipment strait-line">
                            {shipmentDetails?.client_contact?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.company_name}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="right-content d-flex flex-column">
                          <CardTitle className="h4 card-title-new-shipment">
                            Contact Number
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {shipmentDetails?.client_contact?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.mobile_number}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card body className="card-body-new-shipment">
                      <div className="d-flex align-items-center">
                        <div className="left-content">
                          <CardTitle className="h4 card-title-new-shipment strait-line">
                            Customs Broker
                          </CardTitle>
                          <CardText className="card-text-new-shipment strait-line">
                            {shipmentDetails?.broker?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.name}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="right-content d-flex flex-column">
                          <CardTitle className="h4 card-title-new-shipment">
                            Contact Number
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {shipmentDetails?.broker?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.mobile_number}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Number of Containers
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {!shipmentDetails?.total_container
                          ? 0
                          : shipmentDetails?.total_container}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Chemical Containers
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {!shipmentDetails?.chemical_container
                          ? 0
                          : shipmentDetails?.chemical_container}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Physical Containers
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {!shipmentDetails?.physical_container
                          ? 0
                          : shipmentDetails?.physical_container}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Port Name
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.port?.map((text, i) => {
                          return <span key={i}>{text?.name}</span>;
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        {shipmentDetails?.ship_type === 1 ? "Import" : "Export"}{" "}
                        Country
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.importing_exporting_country?.map(
                          (text, i) => {
                            return <span key={i}>{text.name}</span>;
                          }
                        )}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Manufacturing Company Name
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.manufacturing_company_name}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Origin Country
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.origin_country?.map((text, i) => {
                          return <span key={i}>{text?.name}</span>;
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Origin Certificate No.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.origin_certificate_number}
                      </CardText>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Invoice No.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.invoice?.map((text, i) => {
                          return <span key={i}>{text?.id}</span>;
                        })}
                      </CardText>
                    </Card>
                  </Col>

                  {isUserTypeAdmin && (
                    <>
                      <Col lg={4}>
                        <Card body className="card-body-new-shipment">
                          <CardTitle className="h4 card-title-new-shipment">
                            Invoice Total Before Discount
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {shipmentDetails?.tota_amount} Kwd
                          </CardText>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card body className="card-body-new-shipment">
                          <CardTitle className="h4 card-title-new-shipment">
                            Discount
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {shipmentDetails?.invoice[0]?.discount}{" "}
                            {shipmentDetails?.invoice[0]?.discount_type ===
                            "Fixed"
                              ? "KWD"
                              : "%"}
                          </CardText>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card body className="card-body-new-shipment">
                          <CardTitle className="h4 card-title-new-shipment">
                            Invoice Total
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {shipmentDetails?.invoice?.map((text, i) => {
                              return (
                                <span key={i}>
                                  {parseInt(text?.total_amount)} KWD
                                </span>
                              );
                            })}
                          </CardText>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card body className="card-body-new-shipment">
                          <CardTitle className="h4 card-title-new-shipment">
                            Total Amount in Words
                          </CardTitle>
                          <CardText
                            className="card-text-new-shipment"
                            style={{ textTransform: "capitalize" }}
                          >
                            {invoiceTotalAmountInWords &&
                              `${invoiceTotalAmountInWords} Kuwaiti Dinar`}
                          </CardText>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card body className="card-body-new-shipment">
                          <CardTitle className="h4 card-title-new-shipment">
                            Payment Method
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {shipmentDetails?.invoice[0]?.payment_method ??
                              "N/A"}
                          </CardText>
                        </Card>
                      </Col>
                    </>
                  )}

                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Reviewd
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {shipmentDetails?.reviewed === 1 ? "✅" : "❌"}
                      </CardText>
                    </Card>
                  </Col>
                </Row>
                {tableData?.length > 0 && (
                  <Row>
                    <Col xs={12}>
                      <BootstrapTable
                        keyField="barcode"
                        data={tableData}
                        columns={columns}
                      />
                    </Col>
                  </Row>
                )}
                {sampleComponentsDataList?.length > 0 && (
                  <Row>
                    <Col xs={12}>
                      <BootstrapTable
                        keyField="barcode"
                        data={sampleComponentsDataList}
                        columns={datasampleColumn}
                      />
                    </Col>
                  </Row>
                )}
                {isUserTypeAdmin && (
                  <Button
                    type="button"
                    color="success"
                    name={`send-button-`}
                    className="waves-effect waves-light mx-3 btn-success"
                    style={{
                      alignItems: "center",
                      borderRadius: "25px",
                      padding: "6px 12px",
                      fontSize: "14px",
                      width: "200px",
                    }}
                    disabled={shipmentDetails.reviewed === 1}
                    onClick={() => markReviewed()}
                    // disabled={ data.epa_status === 2 }
                  >
                    <i
                      className=" mdi mdi-eye-plus"
                      style={{ marginRight: "10px" }}
                    />
                    <span>Mark Reviewed</span>
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ShipmentPreview;
