import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import "../../../assets/custom/custom2.css";

import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../../api/ApiUtils";
import { useNavigate } from "react-router-dom";
import { ToasterError } from "../../../helper/ToasterHelper";
import { ports } from "../ShipmentSteps/supportData";
const InvoiceReport = () => {
  const [modalCenter, setModalCenter] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [shipmentListData, setShipmentListData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("invoiceID");
  const [sortDirection, setSortDirection] = useState("desc");
  const [filterType, setFilterType] = useState("");
  const [count, setCount] = useState();
  const [brokerList, setBrokerList] = useState([]);
  const [dataSampleList, setDataSampleList] = useState([]);
  const [shipmentType, setShipmentType] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [formData, setFormData] = useState({
    invoiceID: "",
    paymentMethod: "",
    port: "",
    status: "",
    end_date: "",
    start_date: "",
  });
  async function fetchShipmentList() {
    console.log("first");
    let url = `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&invoiceID=${formData.invoiceID}&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${formData.port}&invoiceStatus=${formData.status}&startDate=${formData.start_date}&endDate=${formData.end_date}`;
    await ApiUtils.getInvoiceFilter(url)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setTotalRecords(res ? res.data.data.totalCount : 0);

          setShipmentListData(res.data.data.invoices);
        }
      })
      .catch((err) => setShipmentListData([]));
  }

  // async function fetchShipmentCount() {
  //   await ApiUtils.getShipmentCount(
  //     `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&customs_decleration_number=${formData.customs_id}&company=${formData.company}&container_id=${formData.container_id}&data_sample=${formData.data_sample}&port=${formData.port}&filterShipmentType=${formData.shipment_type}&barcode=${formData.bar_code}&broker=${formData.broker}&start_date=${formData.start_date}&to_date=${formData.end_date}`
  //   )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setCount(res?.data?.data);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error && error.data && error.data.message) {
  //         ToasterError(error.data.message);
  //       } else if (error && error.data) {
  //         ToasterError(error.data);
  //       } else if (error) {
  //         ToasterError(error);
  //       }
  //     });
  // }
  async function fetchBrokerList() {
    await ApiUtils.getBrokersList()
      .then((res) => {
        setBrokerList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  async function fetchDataSampleList() {
    await ApiUtils.getDataSample()
      .then((res) => {
        setDataSampleList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchContainerTypeList() {
    await ApiUtils.getContainerTypeList()
      .then((res) => {
        setShipmentType(res.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }
  async function fetchCompanyList() {
    await ApiUtils.getContactsList()
      .then((res) => {
        setCompanyList(res.data.data.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  useEffect(() => {
    fetchShipmentList(filterType);

    //fetchShipmentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);
  const navigate = useNavigate();

  const onPreviewDataSample = (row) => {
    navigate(`/invoice-preview/${row?.invoiceID}`);
  };
  const sortShipmentFunc = async (field, order) => {
    await ApiUtils.getInvoiceFilter(
      `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&invoiceID=${formData.invoiceID}&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${formData.port}&invoiceStatus=${formData.status}&startDate=${formData.start_date}&endDate=${formData.end_date}`
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setTotalRecords(res ? res.data.data.totalCount : 0);
          setShipmentListData(res.data.data.invoices);
        }
      })
      .catch((err) => setShipmentListData([]));
  };

  const togCenter = () => {
    fetchBrokerList();
    fetchDataSampleList();
    fetchContainerTypeList();
    fetchCompanyList();
    setModalCenter(!modalCenter);
    setFormData({
      invoiceID: "",
      paymentMethod: "",
      port: "",
      status: "",
      end_date: "",
      start_date: "",
    });
  };

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const columns = [
    {
      dataField: "invoiceID",
      text: "Inv No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "shipmentCDN",
      text: "CDN",
      // sort: true,
      // onSort: async (field, order) => {
      //   setSortColumn(field);
      //   setSortDirection(order);
      //   sortShipmentFunc(field, order);
      // },
    },
    {
      dataField: "invoiceTotalAmount",
      text: "Amount",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "companyName",
      text: "Company",
    },
    {
      dataField: "invoicePaymentMethod",
      text: "Method",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
      formatter: (_cellContent) =>
        _cellContent === null ? "----" : _cellContent,
    },
    {
      dataField: "invoiceStatus",
      text: "Status",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
      formatter: (cell) => {
        return (
          <div>
            {cell === 2 ? (
              <span className="badge bg-success font-size-12">Paid</span>
            ) : (
              <span className="badge bg-warning font-size-12">
                {"Not Paid"}
              </span>
            )}
          </div>
        );
      },
    },

    {
      dataField: "invoiceCreatedAt",
      text: "Invoice Date",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "shipmentPortName",
      text: "Port Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      formatter: (_cellContent, row) => (
        <>
          <Button
            className="me-3 text-info tooltip-on-action btn1"
            onClick={() => onPreviewDataSample(row)}
          >
            <i className="ri ri-eye-line font-size-18"></i>
            <span className="tooltiptext">View</span>
          </Button>
        </>
      ),
    },
  ];
  const toggleDropdown = () => {
    setisOpen(!isOpen);
  };
  const modalClose = () => {
    setModalCenter(false);
    setFormData({
      invoiceID: "",
      paymentMethod: "",
      port: "",
      status: "",
      end_date: "",
      start_date: "",
    });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  async function fetchShipmentListFilter(fill) {
    console.log(formData);
    setFilterType(fill);
    await ApiUtils.getInvoiceFilter(
      `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}&invoiceID=${formData.invoiceID}&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${formData.port}&invoiceStatus=${formData.status}&startDate=${formData.start_date}&endDate=${formData.end_date}`
    )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(res);
          setTotalRecords(res ? res.data.data.totalCount : 0);
          setShipmentListData(res.data.data.invoices);
          setModalCenter(false);
          //   setCount({
          //     shipmentCount: res.data.data.shipmentCount,
          //     shipmentLessThen50KgCount: res.data.data.shipmentLessThen50KgCount,
          //     shipmentUnderKepaCount: res.data.data.shipmentUnderKepaCount,
          //   });
          //fetchShipmentCount();
        }
      })
      .catch((err) => setShipmentListData([]));
  }

  const onSubmitUser = () => {
    fetchShipmentListFilter();
  };

  const handleDownload = () => {
    if (
      formData.invoiceID === "" &&
      formData.status === "" &&
      formData.port === "" &&
      formData.paymentMethod === "" &&
      formData.start_date === "" &&
      formData.end_date === ""
    ) {
      ApiUtils.getInvoiceReport(
        `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${totalRecords}&page=${currentPage}&invoiceID=${formData.invoiceID}&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${formData.port}&invoiceStatus=${formData.status}&startDate=${formData.start_date}&endDate=${formData.end_date}`
      )
        .then((res) => {
          if (res.status === 200) {
            //window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    } else {
      ApiUtils.getInvoiceReport(
        `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${totalRecords}&page=${1}&invoiceID=${
          formData.invoiceID
        }&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${
          formData.port
        }&invoiceStatus=${formData.status}&startDate=${
          formData.start_date
        }&endDate=${formData.end_date}`
      )
        .then((res) => {
          if (res.status === 200) {
            //window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };
  const handleDownloadCsv = () => {
    if (
      formData.invoiceID === "" &&
      formData.status === "" &&
      formData.port === "" &&
      formData.paymentMethod === "" &&
      formData.start_date === "" &&
      formData.end_date === ""
    ) {
      ApiUtils.getInvoiceReportCsv(
        `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${totalRecords}&page=${currentPage}&invoiceID=${formData.invoiceID}&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${formData.port}&invoiceStatus=${formData.status}&startDate=${formData.start_date}&endDate=${formData.end_date}`
      )
        .then((res) => {
          if (res.status === 200) {
            //window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    } else {
      ApiUtils.getInvoiceReportCsv(
        `sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${totalRecords}&page=${1}&invoiceID=${
          formData.invoiceID
        }&invoicePaymentMethod=${formData.paymentMethod}&shipmentPortName=${
          formData.port
        }&invoiceStatus=${formData.status}&startDate=${
          formData.start_date
        }&endDate=${formData.end_date}`
      )
        .then((res) => {
          if (res.status === 200) {
            //window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const handleChange = (e, { name }) => {
    if (name === "port") {
      const selectedValue = e.label;
      setFormData({
        ...formData,
        [name]: selectedValue,
      });
    } else if (e?.label) {
      const selectedValue = e.value;
      setFormData({
        ...formData,
        [name]: selectedValue,
      });
    } else {
      setFormData({
        ...formData,
        [e?.target?.name]: e?.target?.value,
      });
      console.log(e.target.name, e.target.name);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">invoice Report</h4>

                <div className="page-title-right">
                  <div className="my-4 text-center d-flex align-items-center justify-content-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light me-4"
                      onClick={togCenter}
                    >
                      <i className="ri-phone-find-fill font-size-15 mr-1" />
                      Find Invoice
                    </Button>

                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        color="info"
                        className="waves-effect waves-light"
                      >
                        <i className=" ri-download-2-fill font-size-15 me-1 mt-3" />
                        Download
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={handleDownload}>
                          PDF
                        </DropdownItem>
                        <DropdownItem onClick={handleDownloadCsv}>
                          CSV
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop={true}
                  >
                    <ModalHeader toggle={modalClose}>Find Invoice</ModalHeader>
                    <ModalBody>
                      <AvForm
                        onSubmit={(e, error, value) =>
                          onSubmitUser(e, error, value)
                        }
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="invoiceID"
                                label="invoice ID"
                                type="text"
                                onChange={handleChange}
                                value={formData.invoiceID}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Payment Method
                              </Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="paymentMethod"
                                options={[
                                  { label: "cash", value: "cash" },
                                  { label: "KNet", value: "KNet" },
                                ]}
                                onChange={handleChange}
                                defaultValue={formData.paymentMethod}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">status</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="status"
                                options={[
                                  { label: "Paid", value: 2 },
                                  { label: "Not Paid", value: 1 },
                                ]}
                                onChange={handleChange}
                                defaultValue={formData.status}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Port</Label>
                              <Select
                                validate={{ required: { value: true } }}
                                name="port"
                                options={ports}
                                onChange={handleChange}
                                defaultValue={formData.port}
                                classNamePrefix="select2-selection"
                                required
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="shipment-date-input"
                                className="form-label"
                              >
                                Start Date
                              </Label>
                              <AvField
                                type="date"
                                id="shipment-date-input"
                                name="start_date"
                                onChange={handleChange}
                                value={formData?.start_date}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="shipment-date-input"
                                className="form-label"
                              >
                                End Date
                              </Label>
                              <AvField
                                type="date"
                                id="shipment-date-input"
                                name="end_date"
                                onChange={handleChange}
                                value={formData?.end_date}
                              />
                            </div>
                          </Col>
                        </Row>

                        <FormGroup className="mb-0" style={{ float: "right" }}>
                          <div>
                            <Button type="submit" color="info" className="me-1">
                              FIND INVOICES
                            </Button>{" "}
                            <Button
                              type="reset"
                              color="secondary"
                              onClick={modalClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={shipmentListData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={shipmentListData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2"></Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex mt-3">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InvoiceReport;
