import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import dataSampleSlice from "./slices/dataSampleSlice";
import brokerSlice from "./slices/brokerSlice";
import shipmentSlice from "./slices/shipmentSlice";
import invoiceSlice from "./slices/invoiceSlice";

const rootReducer = combineReducers({
  authSlice,
  dataSampleSlice,
  brokerSlice,
  shipmentSlice,
  invoiceSlice,
});
export default rootReducer;
