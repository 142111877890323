import React, { useEffect } from "react";
import ApiUtils from "../api/ApiUtils";
import { useLocation, useNavigate } from "react-router-dom";

const PayNow = () => {
  const navigate = useNavigate();

  const search = useLocation().search;
  const invoiceId = new URLSearchParams(search).get("invoiceId");

  useEffect(() => {
    handlePayNow(invoiceId);
  }, []);
  const handlePayNow = (invoiceId) => {
    ApiUtils.generatePaymentUrl(`id=${invoiceId}`)
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data;
        }
      })
      .catch((error) => {
        navigate(`/`);
        return error;
      });
  };
  return <></>;
};

export default PayNow;
