import React, { useState, useEffect } from 'react';
import ApiUtils from "../../api/ApiUtils";
import Select from "react-select";

const PortsDroplist = ({ defaultSelectedValue, updateUserPort }) => {
    const [ports, setPorts] = useState([]);
    const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

    // Simulating an API call to fetch ports data
    async function fetchPorts() {
        await ApiUtils.getPortList()
            .then((res) => {
                if (res.status === 200) {
                    let portData = res.data.data.map((val) => ({
                        ...val,
                        value: val.id,
                        label: val.name,
                    }));
                    setPorts(portData);
                }
            })
            .catch((err) => setPorts([]));
    }

    useEffect(() => {
        fetchPorts();
    }, []);

    const options = ports.map((port) => ({
        value: port.id,
        label: port.name,
    }));

    const handleSelectChange = (selectedOption) => {
        const newValue = selectedOption ? selectedOption.value : null;
        setSelectedValue(newValue);
        updateUserPort(newValue);
    };

    return (
        <div>
            <label htmlFor={"port_id"}>Select Port</label>
            <Select
                id={"port_id"}
                value={options.find(option => option.value === selectedValue)}
                options={options}
                onChange={handleSelectChange}
            />
        </div>
    );
};

export default PortsDroplist;
