import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import swal from "sweetalert";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../api/ApiUtils";
import { USER_TYPE } from "../../config/constants";
import {
  ToasterError,
  ToasterSuccess,
  ToasterWarning,
} from "../../helper/ToasterHelper";
import { useSelector } from "react-redux";
import PortsDroplist from "../../components/Common/PortsDroplist";

function UserList() {
  const [modalCenter, setModalCenter] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [editUserId, setEditUserId] = useState("");
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordId, setPasswordId] = useState();
  const userData = useSelector((state) => state.authSlice);

  const [user, setUser] = useState({
    name: "",
    title: "",
    email: "",
    password: "",
    role_id: "",
    port_id: "",
    role_name: "",
  });
  const togCenter = () => {
    setModalCenter(!modalCenter);
    removeBodyCss();
    setUser({
      name: "",
      title: "",
      email: "",
      password: "",
      role_id: "",
      port_id: "",
      role_name: "",
    });
  };
  const passwordTog = () => {
    setPasswordModal(!passwordModal);
    removeBodyCss();
  };

  const fetchUserList = async () => {
    await ApiUtils.getUserList(
      `search=${searchValue}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then(async (res) => {
        if (res?.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setUsersList(res ? res.data.data.data : []);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
        setUsersList([]);
      });
  };

  useEffect(() => {
    fetchUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  useEffect(() => {
    ApiUtils.getUserRoles().then((res) => {
      console.log(res);
      setOptionGroup([
        {
          label: "role",
          options: res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id,
              guardName: el.guard_name,
            };
          }),
        },
      ]);
    });
  }, []);

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  const updateUserPort = (newPortId) => {
    setUser({ ...user, port_id: newPortId });
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const [optionGroup, setOptionGroup] = useState([]); // [

  const deleteUser = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApiUtils.deleteUser(data.id)
          .then((res) => {
            if (res.status === 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setCurrentPage(1);
              fetchUserList();
            }
          })
          .catch((error) => {
            if (error && error.data && error.data.message) {
              ToasterError(error.data.message);
            } else if (error && error.data) {
              ToasterError(error.data);
            } else if (error) {
              ToasterError(error);
            }
          });
      } else {
        swal({ title: "User not deleted!", icon: "error" });
      }
    });
  };
  const editUser = async (data) => {
    removeBodyCss();
    let updatedUserData = {
      name: data.name || "",
      title: data.title || "",
      email: data.email || "",
      password: data.password || "",
      role_id: data.role_id,
      port_id: data.port_id || "",
      role_name: data.role_name,
      role_value: data.role_value,
    };
    setEditUserId(data.id);
    await setUser(updatedUserData);
    await setIsEditMode(true);
    await setModalCenter(!modalCenter);
  };
  const userStatus = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to update locked user",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        ApiUtils.updateUserStatus({
          id: data?.id,
          status: data?.status === "ACTIVE" ? 2 : 1,
        })
          .then((res) => {
            if (res.status === 200) {
              swal(res.data.message, {
                icon: "success",
              });
              fetchUserList();
            }
          })
          .catch((error) => {
            if (error && error.data && error.data.message) {
              ToasterError(error.data.message);
            } else if (error && error.data) {
              ToasterError(error.data);
            } else if (error) {
              ToasterError(error);
            }
          });
      } else {
        swal({
          title: "User locked not updated!",
          icon: "error",
        });
      }
    });
  };
  const columns = [
    {
      dataField: "name",
      text: "Full Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "title",
      text: "Job Title",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "role_name",
      text: "User Role",
      sort: true,
      onSort: (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "port.name",
      text: "Assigned Port",
      sort: true,
      onSort: (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
      onSort: (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "last_login",
      text: "Last Login",
      sort: true,
      onSort: (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "status",
      text: "Locked",
      sort: true,
      onSort: (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortUserFunc(field, order);
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Actions",
      formatter: (_cellContent, row) => (
        <>
          <UncontrolledDropdown className="text-center">
            <DropdownToggle tag="i" className="arrow-none">
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => editUser(row)}
              >
                <i className="mdi mdi-pencil font-size-18"></i>
                Update
              </DropdownItem>

              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => userStatus(row)}
              >
                <i
                  // className="mdi mdi-block-helper font-size-18"
                  className={
                    row.status === "ACTIVE"
                      ? "fas fa-lock-open font-size-17"
                      : "fas fa-lock font-size-17"
                  }
                ></i>
                {row.status === "ACTIVE" ? "Lock" : "Unlock"}
              </DropdownItem>

              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() =>
                  userData?.userDetails?.userEmail !== row.email
                    ? deleteUser(row)
                    : ""
                }
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
                Delete
              </DropdownItem>
              <DropdownItem
                className="action-dropdown-invoice"
                onClick={() => updatePassword(row)}
              >
                <i className=" ri-lock-password-fill font-size-18"></i>
                Update Password
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  const updatePassword = (row) => {
    setPasswordModal(row.id);
    setPasswordId(row.id);
  };

  const handleUpdatePassword = (e) => {
    setNewPassword({
      [e?.target?.name]: e?.target?.value,
    });
  };

  const updateNewPassword = (e, error, values) => {
    if (error?.length > 0) {
      ToasterWarning(`Please fill all field`);
    } else {
      ApiUtils.updateUserPassword({
        id: passwordId,
        password: newPassword.repeatnewpassword,
      })
        .then((res) => {
          if (res.status === 200) {
            ToasterSuccess(res.data.message);
            setPasswordModal(false);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const handleUserInput = (e) => {
    if (e?.label) {
      setUser({
        ...user,
        role_id: e.value,
      });
    } else {
      setUser({
        ...user,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };
  const modalClose = () => {
    setModalCenter(false);
    setIsEditMode(false);
    setEditUserId("");
    setUser({
      name: "",
      title: "",
      email: "",
      password: "",
      role_id: "",
      port_id: "",
    });
  };
  const passwordModalClose = () => {
    setPasswordModal(false);
  };

  const onSubmitUser = async (_e, error, _values) => {
    if (error?.length > 0) {
      ToasterWarning("Please fill all the input field");
    } else {
      const body = {
        id: editUserId,
        name: user.name,
        title: user.title,
        role_id:
          typeof user.role_id == "string"
            ? optionGroup[0].options.find(
                (el) => el.guardName === user.role_id.toLowerCase()
              )?.value || ""
            : user.role_id,
        port_id: user.port_id,
      };
      performOperation(isEditMode, isEditMode ? body : user)
        .then((res) => {
          if (res.status === 200) {
            setModalCenter(false);
            fetchUserList();
            setIsEditMode(false);
            setEditUserId("");
            ToasterSuccess(res.data.message);
            if (!isEditMode) {
              setTimeout(() => {
                setUser({
                  name: "",
                  title: "",
                  email: "",
                  password: "",
                  role_id: "",
                  port_id: "",
                });
              }, 100);
            }
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const performOperation = (checkMode, params) =>
    !checkMode ? ApiUtils.createUser(params) : ApiUtils.userUpdate(params);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getUserList(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setUsersList(res.data.data.data);
        }
      })
      .catch((err) => setUsersList([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const sortUserFunc = async (field, order) => {
    await ApiUtils.getUserList(
      `search=${searchValue}&sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setUsersList(res.data.data.data);
        }
      })
      .catch((err) => setUsersList([]));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Users List</h4>

                <div className="page-title-right">
                  <div className="my-4 text-center">
                    <Button
                      type="button"
                      color="info"
                      className="waves-effect waves-light"
                      onClick={togCenter}
                    >
                      Add User
                    </Button>
                  </div>

                  <Modal
                    isOpen={modalCenter}
                    toggle={togCenter}
                    centered={true}
                    backdrop="static"
                  >
                    <ModalHeader toggle={modalClose}>
                      {isEditMode ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm
                        autoComplete="off"
                        onSubmit={(e, error, values) =>
                          onSubmitUser(e, error, values)
                        }
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="name"
                                label="Full Name"
                                placeholder="Enter Full name"
                                type="text"
                                errorMessage="Enter Name"
                                validate={{ required: { value: true } }}
                                onChange={handleUserInput}
                                value={user?.name}
                                autoComplete="off"
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="title"
                                label="Job Title"
                                placeholder="Enter Job Title"
                                type="text"
                                errorMessage="Enter Text Only"
                                validate={{ required: { value: true } }}
                                onChange={handleUserInput}
                                value={user?.title}
                                autoComplete="off"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email Address"
                                placeholder="Enter Email Address"
                                type="email"
                                errorMessage="Invalid Email"
                                validate={{
                                  required: { value: true },
                                  email: { value: true },
                                }}
                                onChange={handleUserInput}
                                value={user.email}
                                autoComplete="off"
                                readOnly={isEditMode ? true : false}
                              />
                            </div>
                          </Col>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <PortsDroplist
                                  defaultSelectedValue={user.port_id}
                                  updateUserPort={updateUserPort}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">User Role</Label>
                                <Select
                                  defaultInputValue={
                                    user?.role_id !== "" ? user.role_id : ""
                                  }
                                  onChange={handleUserInput}
                                  options={optionGroup}
                                  classNamePrefix="select2-selection"
                                  required
                                  autoComplete="off"
                                />
                              </div>
                            </Col>
                          </Row>
                          {!isEditMode && (
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  name="password"
                                  label="Account Password"
                                  type="password"
                                  placeholder="Password"
                                  errorMessage="Enter password"
                                  validate={{
                                    required: {
                                      value: isEditMode ? false : true,
                                    },
                                  }}
                                  onChange={handleUserInput}
                                  autoComplete="new-password"
                                />
                              </div>
                            </Col>
                          )}
                          {/* </Col> */}

                          {!isEditMode && (
                            <Col md={6}>
                              <div className="mb-3">
                                <AvField
                                  label="Repeat Password"
                                  name="password1"
                                  type="password"
                                  placeholder="Repeat password"
                                  errorMessage="Password does not match"
                                  validate={{
                                    required: {
                                      value: true,
                                    },
                                    match: { value: "password" },
                                  }}
                                  onChange={handleUserInput}
                                  autoComplete="new-password"
                                />
                              </div>
                            </Col>
                          )}
                        </Row>

                        <FormGroup className="mb-0" style={{ float: "right" }}>
                          <div>
                            <Button type="submit" color="info" className="me-1">
                              {isEditMode ? "Update" : "Save"}
                            </Button>{" "}
                            <Button
                              type="reset"
                              color="secondary"
                              onClick={modalClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      sizePerPage: sizePerPage,
                      totalSize: Number(totalRecords),
                      page: currentPage,
                    })}
                    keyField="id"
                    columns={columns}
                    data={usersList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={usersList}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={4}>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Label
                                      htmlFor="shipment-date-input"
                                      className="form-label"
                                    >
                                      Search
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      onChange={(e) => onSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    hover={true}
                                    pagination={paginationFactory}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap bg-white"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={passwordModal}
            toggle={passwordTog}
            centered={true}
            backdrop="static"
          >
            <ModalHeader toggle={passwordModalClose}>
              Update Password
            </ModalHeader>
            <ModalBody>
              <AvForm
                autoComplete="off"
                onSubmit={(e, error, values) =>
                  updateNewPassword(e, error, values)
                }
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="newpassword"
                        label="Account Password"
                        type="password"
                        placeholder="Password"
                        errorMessage="Enter password"
                        validate={{
                          required: {
                            value: isEditMode ? false : true,
                          },
                        }}
                        onChange={handleUpdatePassword}
                        autoComplete="new-password"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        label="Repeat Password"
                        name="repeatnewpassword"
                        type="password"
                        placeholder="Repeat password"
                        errorMessage="Password does not match"
                        validate={{
                          required: {
                            value: true,
                          },
                          match: { value: "newpassword" },
                        }}
                        onChange={handleUpdatePassword}
                        autoComplete="new-password"
                      />
                    </div>
                  </Col>
                </Row>

                <FormGroup className="mb-0" style={{ float: "right" }}>
                  <div>
                    <Button type="submit" color="info" className="me-1">
                      Update
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={passwordModalClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserList;
