const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  brokers: "",
};

const brokerSlice = createSlice({
  name: "brokersList",
  initialState,
  reducers: {
    getBrokerList: (state, action) => {
      state.brokers = action.payload;
    },
    createBroker: (state, action) => {
      state.brokers = action.payload;
    },
    updateBroker: (state, action) => {
      state.brokers = action.payload;
    },
    deleteBroker: (state, action) => {
      state.brokers = action.payload;
    },
  },
});

export const { getBrokerList, createBroker, updateBroker } =
  brokerSlice.actions;
export default brokerSlice.reducer;
